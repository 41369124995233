/* eslint-disable */
import ServerUtils from "./helpers";

export default class DeveloperUtils {
  static async restartService(service) {
    return await ServerUtils.GET('api/developer/restart-service/' + service.id)
  }
  static async restartServer() {
    return await ServerUtils.POST('api/developer/restart-server')
  }
  static async getSockets() {
    return await ServerUtils.POST('api/developer/get-sockets')
  }
  static async getLogs() {
    return await ServerUtils.GET('api/developer/logs')
  }
  static async getSettings() {
    return await ServerUtils.GET('api/developer/settings/get')
  }
  static async setSettings(settings) {
    return await ServerUtils.POST('api/developer/settings/set', {settings: settings})
  }
  static async deleteLog(fileName) {
    return await ServerUtils.POST('api/developer/logs/delete', {fileName: fileName})
  }
  static async getVitals() {
    return await ServerUtils.GET('api/developer/vitals')
  }
  static async getAllTickets() {
    return await ServerUtils.POST('api/developer/get-all-tickets')
  }
  static async updateTicketStatus(ticket) {
    return await ServerUtils.POST('api/developer/update-ticket-status', {ticket: ticket})
  }
  static async sendMessage(msg) {
    return ServerUtils.POST('api/messages/send', { message: msg })
  }
}