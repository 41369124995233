import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box } from '@mui/material';
//
import Logo from './Logo';
import ProgressBar from './ProgressBar';
import LogoSpinner from './LogoSpinner';

import cssStyles from '../utils/cssStyles';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...cssStyles(theme).bgBlur({ blur: 64, color: theme.palette.background.paper, opacity: .15 })
}));

const RootStyle2 = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
  ...cssStyles(theme).bgBlur({blur: 2}),
}));

// ----------------------------------------------------------------------

LoadingScreen.propTypes = {
  isDashboard: PropTypes.bool,
};

export default function LoadingScreen({ isDashboard, ...other }) {
  return (
    <>

      {!isDashboard ? (
        <RootStyle {...other}>
          <LogoSpinner />
        </RootStyle>
      ) : (
        <RootStyle2 {...other}>
          <LogoSpinner />
        </RootStyle2>
      )}
      <ProgressBar />
    </>
  );
}
