/* eslint-disable */
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, color = "primary", sx }) {
  const theme = useTheme();

  const LIGHTER = theme.palette.primary.lighter;
  const LIGHT = theme.palette.primary.light;
  const MAIN = theme.palette.primary.main;
  const DARK = theme.palette.primary.dark;
  const DARKER = theme.palette.primary.darker;

  const logo = (
    <Box sx={{ width: 64, height: 64, transform: 'scale(1.5)', ...sx }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0"
        y="0"
        version="1.1"
        viewBox="0 0 4000 4000"
        xmlSpace="preserve"
      >
        <defs>
          <path
            id="SVGID_1_"
            d="M2231.487 1929.11l-229.207 55.746c82.079 76.768 253.639 44.906 360.565-2.739-7.139-26.856-11.979-54.051-14.847-81.342l-116.511 28.335z"
          ></path>
        </defs>
        <clipPath id="SVGID_00000117667082930995017370000011509482564955994277_">
          <use overflow="visible" xlinkHref="#SVGID_1_"></use>
        </clipPath>
        <linearGradient
          id="SVGID_00000137097784117752912110000015198990810150570174_"
          x1="2209.291"
          x2="2080.357"
          y1="2024.105"
          y2="1817.137"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor={MAIN}></stop>
          <stop offset="1" stopColor={DARK}></stop>
        </linearGradient>
        <path
          fill="url(#SVGID_00000137097784117752912110000015198990810150570174_)"
          d="M2002.28 1900.775H2362.845V2061.6240000000003H2002.28z"
          clipPath="url(#SVGID_00000117667082930995017370000011509482564955994277_)"
        ></path>
        <defs>
          <filter
            id="Adobe_OpacityMaskFilter"
            width="349.192"
            height="89.685"
            x="2002.28"
            y="1900.775"
            filterUnits="userSpaceOnUse"
          >
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"></feColorMatrix>
          </filter>
        </defs>
        <mask
          id="SVGID_00000158707588901907406550000007055968045816696510_"
          width="349.192"
          height="89.685"
          x="2002.28"
          y="1900.775"
          maskUnits="userSpaceOnUse"
        >
          <g filter="url(#Adobe_OpacityMaskFilter)">
            <linearGradient
              id="SVGID_00000061467465168977841800000004045619168834118065_"
              x1="909.129"
              x2="-89.803"
              y1="4620.676"
              y2="4268.249"
              gradientTransform="translate(1567.243 -2569.394)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#FFF"></stop>
              <stop offset="1"></stop>
            </linearGradient>
            <path
              fill="url(#SVGID_00000061467465168977841800000004045619168834118065_)"
              d="M2002.28 1900.775H2351.4719999999998V1990.462H2002.28z"
            ></path>
          </g>
        </mask>
        <path
          fill={DARKER}
          fillRule="evenodd"
          d="M2231.487 1929.11l-229.207 55.746s195.44 32.073 349.192-57.253a561.48 561.48 0 01-3.474-26.828l-116.511 28.335z"
          clipRule="evenodd"
          mask="url(#SVGID_00000158707588901907406550000007055968045816696510_)"
        ></path>
        <defs>
          <path
            id="SVGID_00000008846178124317235400000011748195293990609821_"
            d="M2153.642 1844.946l-288.641 22.007c95.233 123.641 356.843 101.581 487.67 52.387-5.165-29.478-7.972-59.218-8.776-88.9l-190.253 14.506z"
          ></path>
        </defs>
        <clipPath id="SVGID_00000072258210455667082990000009775839074331833266_">
          <use
            overflow="visible"
            xlinkHref="#SVGID_00000008846178124317235400000011748195293990609821_"
          ></use>
        </clipPath>
        <linearGradient
          id="SVGID_00000011003458940519268450000004140140389032085925_"
          x1="2104.913"
          x2="1730.148"
          y1="1911.548"
          y2="2010.086"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor={MAIN}></stop>
          <stop offset="1" stopColor={DARK}></stop>
        </linearGradient>
        <path
          fill="url(#SVGID_00000011003458940519268450000004140140389032085925_)"
          d="M1865.001 1830.44H2352.671V1990.594H1865.001z"
          clipPath="url(#SVGID_00000072258210455667082990000009775839074331833266_)"
        ></path>
        <defs>
          <path
            id="SVGID_00000014608165590355469740000009914202074641923479_"
            d="M2063.365 1715.134l-366.115-40.774c99.305 195.821 495.023 217.106 647.152 169.149-1.522-32.449-.636-64.869 2.15-96.84l-283.187-31.535z"
          ></path>
        </defs>
        <clipPath id="SVGID_00000082351494048452643510000011697247557002703787_">
          <use
            overflow="visible"
            xlinkHref="#SVGID_00000014608165590355469740000009914202074641923479_"
          ></use>
        </clipPath>
        <linearGradient
          id="SVGID_00000108302335446927963540000004504243211292285368_"
          x1="1949.057"
          x2="1578.679"
          y1="1780.947"
          y2="1770.95"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor={MAIN}></stop>
          <stop offset="1" stopColor={DARK}></stop>
        </linearGradient>
        <path
          fill="url(#SVGID_00000108302335446927963540000004504243211292285368_)"
          d="M1697.25 1674.36H2346.552V1891.466H1697.25z"
          clipPath="url(#SVGID_00000082351494048452643510000011697247557002703787_)"
        ></path>
        <defs>
          <path
            id="SVGID_00000111891921826014795790000003262563839018627753_"
            d="M2027.933 1558.932l-400.594-133.799c68.528 259.808 563.433 377.948 717.823 339.977 1.626-25.581 4.495-50.928 8.368-75.819l-7.103-23.982-318.494-106.377z"
          ></path>
        </defs>
        <clipPath id="SVGID_00000127037707431139214490000005173311469678379689_">
          <use
            overflow="visible"
            xlinkHref="#SVGID_00000111891921826014795790000003262563839018627753_"
          ></use>
        </clipPath>
        <linearGradient
          id="SVGID_00000131359849288045409920000005066044525008497795_"
          x1="2042.337"
          x2="1484.269"
          y1="1624.618"
          y2="1511.596"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor={MAIN}></stop>
          <stop offset="1" stopColor={DARK}></stop>
        </linearGradient>
        <path
          fill="url(#SVGID_00000131359849288045409920000005066044525008497795_)"
          d="M1627.339 1425.133H2353.5299999999997V1803.0810000000001H1627.339z"
          clipPath="url(#SVGID_00000127037707431139214490000005173311469678379689_)"
        ></path>
        <defs>
          <path
            id="SVGID_00000135690426131003508360000016948428459773409188_"
            d="M2075.977 1415.889l-361.815-217.868c10.579 276.566 495.425 504.998 639.984 487.276 3.327-20.792 7.358-41.228 11.88-61.228l-2.386-34.964-287.663-173.216z"
          ></path>
        </defs>
        <clipPath id="SVGID_00000062877149764663968020000003284502352552168329_">
          <use
            overflow="visible"
            xlinkHref="#SVGID_00000135690426131003508360000016948428459773409188_"
          ></use>
        </clipPath>
        <linearGradient
          id="SVGID_00000147212539425295008100000003213729623828901782_"
          x1="2155.258"
          x2="1741.732"
          y1="1580.74"
          y2="1113.149"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor={MAIN}></stop>
          <stop offset="1" stopColor={DARK}></stop>
        </linearGradient>
        <path
          fill="url(#SVGID_00000147212539425295008100000003213729623828901782_)"
          d="M1714.162 1198.021H2366.027V1703.019H1714.162z"
          clipPath="url(#SVGID_00000062877149764663968020000003284502352552168329_)"
        ></path>
        <defs>
          <path
            id="SVGID_00000089543886735435481930000011632855094063193735_"
            d="M2160.128 1318.551l-294.725-265.997c-39.821 256.583 364.863 553.759 502.214 564.467 7.226-30.955 15.664-60.74 24.693-88.923l-232.182-209.547z"
          ></path>
        </defs>
        <clipPath id="SVGID_00000062905092447198872400000003780877397198763653_">
          <use
            overflow="visible"
            xlinkHref="#SVGID_00000089543886735435481930000011632855094063193735_"
          ></use>
        </clipPath>
        <linearGradient
          id="SVGID_00000070837969878274730570000014267849773952103359_"
          x1="2305.807"
          x2="1837.095"
          y1="1572.692"
          y2="1006.26"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor={MAIN}></stop>
          <stop offset="1" stopColor={DARK}></stop>
        </linearGradient>
        <path
          fill="url(#SVGID_00000070837969878274730570000014267849773952103359_)"
          d="M1825.582 1052.554H2392.309V1617.0210000000002H1825.582z"
          clipPath="url(#SVGID_00000062905092447198872400000003780877397198763653_)"
        ></path>
        <defs>
          <path
            id="SVGID_00000077282180019948938250000016372070667923863456_"
            d="M2266.083 1256.474l-213.979-281.292c-74.612 210.768 208.337 523.322 334.085 572.536 9.898-32.786 20.737-63.594 31.648-91.759l-151.754-199.485z"
          ></path>
        </defs>
        <clipPath id="SVGID_00000024700173679841987620000005652714282123884716_">
          <use
            overflow="visible"
            xlinkHref="#SVGID_00000077282180019948938250000016372070667923863456_"
          ></use>
        </clipPath>
        <linearGradient
          id="SVGID_00000140719466389036892080000008786085096786069668_"
          x1="2283.711"
          x2="2112.24"
          y1="1528.14"
          y2="996.694"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor={MAIN}></stop>
          <stop offset="1" stopColor={DARK}></stop>
        </linearGradient>
        <path
          fill="url(#SVGID_00000140719466389036892080000008786085096786069668_)"
          d="M1977.491 975.182H2417.837V1547.7179999999998H1977.491z"
          clipPath="url(#SVGID_00000024700173679841987620000005652714282123884716_)"
        ></path>
        <defs>
          <path
            id="SVGID_00000108301636913834357140000011951102391989079212_"
            d="M2401.493 1923.77c-27.088-115.273-30.629-265.396 34.719-443.656l-226.836-298.187s20.997 189.269 103.245 319.781c0 0-108.812 230.078-53.333 462.39 23.046 96.501 74.439 193.385 173.752 274.324 33.108-12.621 65.43-30.041 96.609-53.552 0 .001-88.857-93.873-128.156-261.1"
          ></path>
        </defs>
        <clipPath id="SVGID_00000106859868546405854850000002289822876079726014_">
          <use
            overflow="visible"
            xlinkHref="#SVGID_00000108301636913834357140000011951102391989079212_"
          ></use>
        </clipPath>
        <linearGradient
          id="SVGID_00000083779351357425256820000013935443179552613014_"
          x1="2326.974"
          x2="2396.541"
          y1="2014.11"
          y2="1482.256"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor={MAIN}></stop>
          <stop offset="1" stopColor={DARK}></stop>
        </linearGradient>
        <path
          fill="url(#SVGID_00000083779351357425256820000013935443179552613014_)"
          d="M2203.809 1181.928H2529.6490000000003V2238.423H2203.809z"
          clipPath="url(#SVGID_00000106859868546405854850000002289822876079726014_)"
        ></path>
        <defs>
          <filter
            id="Adobe_OpacityMaskFilter_00000098940560777097570260000014982156531424405421_"
            width="320.272"
            height="1019.399"
            x="2209.376"
            y="1181.928"
            filterUnits="userSpaceOnUse"
          >
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"></feColorMatrix>
          </filter>
        </defs>
        <mask
          id="SVGID_00000066483998410217291020000014057682272534442887_"
          width="320.272"
          height="1019.399"
          x="2209.376"
          y="1181.928"
          maskUnits="userSpaceOnUse"
        >
          <g filter="url(#Adobe_OpacityMaskFilter_00000098940560777097570260000014982156531424405421_)">
            <linearGradient
              id="SVGID_00000047040946272919065540000001427085759309325492_"
              x1="711.168"
              x2="829.799"
              y1="3686.067"
              y2="4614.82"
              gradientTransform="translate(1587.546 -2548.722)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#FFF"></stop>
              <stop offset="1"></stop>
            </linearGradient>
            <path
              fill="url(#SVGID_00000047040946272919065540000001427085759309325492_)"
              d="M2209.376 1181.928H2529.65V2201.327H2209.376z"
            ></path>
          </g>
        </mask>
        <path
          fill={DARKER}
          fillRule="evenodd"
          d="M2401.493 1923.77c-27.088-115.273-30.629-265.396 34.719-443.656l-226.836-298.187s109.635 165.317 191.884 295.826c0 0-108.813 230.081-53.335 462.393 21.811 91.331 69.023 183.003 158.213 261.18a402.4 402.4 0 0023.51-16.456c.001.001-88.856-93.873-128.155-261.1"
          clipRule="evenodd"
          mask="url(#SVGID_00000066483998410217291020000014057682272534442887_)"
        ></path>
        <defs>
          <filter
            id="Adobe_OpacityMaskFilter_00000046316608969719195460000018340050981545238931_"
            width="446.822"
            height="467.934"
            x="1865.403"
            y="1052.554"
            filterUnits="userSpaceOnUse"
          >
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"></feColorMatrix>
          </filter>
        </defs>
        <mask
          id="SVGID_00000165215100009928616700000010743588858368906882_"
          width="446.822"
          height="467.934"
          x="1865.403"
          y="1052.554"
          maskUnits="userSpaceOnUse"
        >
          <g filter="url(#Adobe_OpacityMaskFilter_00000046316608969719195460000018340050981545238931_)">
            <linearGradient
              id="SVGID_00000085214271227107712910000010542675319433884328_"
              x1="914.591"
              x2="120.789"
              y1="4036.037"
              y2="3755.727"
              gradientTransform="translate(1553.824 -2615.468)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#FFF"></stop>
              <stop offset="1"></stop>
            </linearGradient>
            <path
              fill="url(#SVGID_00000085214271227107712910000010542675319433884328_)"
              d="M1865.403 1052.557H2312.225V1520.488H1865.403z"
            ></path>
          </g>
        </mask>
        <path
          fill={DARKER}
          fillRule="evenodd"
          d="M1865.403 1052.554s130.857 312.036 439.148 467.934c3.904-9.613 6.683-15.766 7.674-17.921-65.099-50.489-141.393-132.853-196.66-224.239l-250.162-225.774z"
          clipRule="evenodd"
          mask="url(#SVGID_00000165215100009928616700000010743588858368906882_)"
        ></path>
        <defs>
          <filter
            id="Adobe_OpacityMaskFilter_00000133518753018602117360000001341759194269186957_"
            width="566.13"
            height="431.209"
            x="1714.162"
            y="1198.021"
            filterUnits="userSpaceOnUse"
          >
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"></feColorMatrix>
          </filter>
        </defs>
        <mask
          id="SVGID_00000038414972187226927030000006055690212482895522_"
          width="566.13"
          height="431.209"
          x="1714.162"
          y="1198.021"
          maskUnits="userSpaceOnUse"
        >
          <g filter="url(#Adobe_OpacityMaskFilter_00000133518753018602117360000001341759194269186957_)">
            <linearGradient
              id="SVGID_00000053512316782869493250000002506351256443935918_"
              x1="1084.431"
              x2="85.499"
              y1="4239.358"
              y2="3886.932"
              gradientTransform="translate(1538.997 -2604.808)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#FFF"></stop>
              <stop offset="1"></stop>
            </linearGradient>
            <path
              fill="url(#SVGID_00000053512316782869493250000002506351256443935918_)"
              d="M1714.162 1198.018H2280.292V1629.229H1714.162z"
            ></path>
          </g>
        </mask>
        <path
          fill={DARKER}
          fillRule="evenodd"
          d="M2280.291 1589.466c-88.185-41.36-203.545-125.038-290.671-225.578l-275.458-165.867s274.791 322.453 555.035 431.209a898.107 898.107 0 0111.094-39.764"
          clipRule="evenodd"
          mask="url(#SVGID_00000038414972187226927030000006055690212482895522_)"
        ></path>
        <defs>
          <filter
            id="Adobe_OpacityMaskFilter_00000109749027501428172500000004065990065892348819_"
            width="631.504"
            height="282.781"
            x="1627.339"
            y="1425.133"
            filterUnits="userSpaceOnUse"
          >
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"></feColorMatrix>
          </filter>
        </defs>
        <mask
          id="SVGID_00000120537425170747973600000014385848186758365852_"
          width="631.504"
          height="282.781"
          x="1627.339"
          y="1425.133"
          maskUnits="userSpaceOnUse"
        >
          <g filter="url(#Adobe_OpacityMaskFilter_00000109749027501428172500000004065990065892348819_)">
            <linearGradient
              id="SVGID_00000058585358766721851060000009908763623251400836_"
              x1="1038.977"
              x2="40.047"
              y1="4384.602"
              y2="4032.177"
              gradientTransform="translate(1530.486 -2597.094)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#FFF"></stop>
              <stop offset="1"></stop>
            </linearGradient>
            <path
              fill="url(#SVGID_00000058585358766721851060000009908763623251400836_)"
              d="M1627.339 1425.13H2258.842V1707.9140000000002H1627.339z"
            ></path>
          </g>
        </mask>
        <path
          fill={DARKER}
          fillRule="evenodd"
          d="M1627.339 1425.133s307.124 232.957 625.634 282.781a848.315 848.315 0 015.87-32.967c-85.438-21.093-198.274-70.549-299.095-138.787l-332.409-111.027z"
          clipRule="evenodd"
          mask="url(#SVGID_00000120537425170747973600000014385848186758365852_)"
        ></path>
        <defs>
          <filter
            id="Adobe_OpacityMaskFilter_00000117648936890033732640000008531429195864799361_"
            width="548.319"
            height="123.471"
            x="1697.25"
            y="1674.36"
            filterUnits="userSpaceOnUse"
          >
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"></feColorMatrix>
          </filter>
        </defs>
        <mask
          id="SVGID_00000093175040717560023210000002921373501123197847_"
          width="548.319"
          height="123.471"
          x="1697.25"
          y="1674.36"
          maskUnits="userSpaceOnUse"
        >
          <g filter="url(#Adobe_OpacityMaskFilter_00000117648936890033732640000008531429195864799361_)">
            <linearGradient
              id="SVGID_00000055705642639199078630000005293806623823165583_"
              x1="982.005"
              x2="-16.827"
              y1="4517.651"
              y2="4165.327"
              gradientTransform="translate(1537.34 -2588.28)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#FFF"></stop>
              <stop offset="1"></stop>
            </linearGradient>
            <path
              fill="url(#SVGID_00000055705642639199078630000005293806623823165583_)"
              d="M1697.25 1674.36H2245.567V1797.831H1697.25z"
            ></path>
          </g>
        </mask>
        <path
          fill={DARKER}
          fillRule="evenodd"
          d="M1697.25 1674.36s203.678 147.15 546.939 120.167c.339-7.827.801-15.58 1.38-23.242-86.299-4.455-199.26-27.957-305.444-69.874l-242.875-27.051z"
          clipRule="evenodd"
          mask="url(#SVGID_00000093175040717560023210000002921373501123197847_)"
        ></path>
        <defs>
          <filter
            id="Adobe_OpacityMaskFilter_00000064337717184073903270000008461551050841705148_"
            width="382.473"
            height="48.334"
            x="1865.001"
            y="1854.628"
            filterUnits="userSpaceOnUse"
          >
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"></feColorMatrix>
          </filter>
        </defs>
        <mask
          id="SVGID_00000129905121470938998550000013478710605493250949_"
          width="382.473"
          height="48.334"
          x="1865.001"
          y="1854.628"
          maskUnits="userSpaceOnUse"
        >
          <g filter="url(#Adobe_OpacityMaskFilter_00000064337717184073903270000008461551050841705148_)">
            <linearGradient
              id="SVGID_00000117639478007955328220000003841243815090286769_"
              x1="930.222"
              x2="-68.708"
              y1="4607.688"
              y2="4255.262"
              gradientTransform="translate(1553.785 -2577.972)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#FFF"></stop>
              <stop offset="1"></stop>
            </linearGradient>
            <path
              fill="url(#SVGID_00000117639478007955328220000003841243815090286769_)"
              d="M1865.001 1854.631H2247.474V1902.9640000000002H1865.001z"
            ></path>
          </g>
        </mask>
        <path
          fill={DARKER}
          fillRule="evenodd"
          d="M1865.001 1866.953s192.769 54.648 382.473 29.301a616.962 616.962 0 01-2.91-34.449c-64.464 5.94-141.574 4.418-217.935-7.177l-161.628 12.325z"
          clipRule="evenodd"
          mask="url(#SVGID_00000129905121470938998550000013478710605493250949_)"
        ></path>
        <defs>
          <path
            id="SVGID_00000143578918403897679570000000090607227251627916_"
            d="M1876.336 2884.281c175.758-15.859 305.313-331.54 289.829-423.885a755.484 755.484 0 01-42.27-.588l-247.559 424.473z"
          ></path>
        </defs>
        <clipPath id="SVGID_00000165229875631283923710000012043425826657501572_">
          <use
            overflow="visible"
            xlinkHref="#SVGID_00000143578918403897679570000000090607227251627916_"
          ></use>
        </clipPath>
        <linearGradient
          id="SVGID_00000098911311951682980830000015981540669086565776_"
          x1="1843.133"
          x2="2009.502"
          y1="2888.104"
          y2="2694.701"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor={DARK}></stop>
          <stop offset="1" stopColor={MAIN}></stop>
        </linearGradient>
        <path
          fill="url(#SVGID_00000098911311951682980830000015981540669086565776_)"
          d="M1876.336 2459.808H2181.649V2884.2799999999997H1876.336z"
          clipPath="url(#SVGID_00000165229875631283923710000012043425826657501572_)"
        ></path>
        <defs>
          <path
            id="SVGID_00000125595136538025236110000003828014744873922461_"
            d="M2039.131 2695.915l-68.857 298.573c188-69.389 236.643-453.826 192.343-550.487a847.158 847.158 0 01-34.834 9.142l-54.029 92.636-34.623 150.136z"
          ></path>
        </defs>
        <clipPath id="SVGID_00000158731766686034618320000007877094560139081628_">
          <use
            overflow="visible"
            xlinkHref="#SVGID_00000125595136538025236110000003828014744873922461_"
          ></use>
        </clipPath>
        <linearGradient
          id="SVGID_00000053507591712166881180000007460362432420398497_"
          x1="1950.233"
          x2="2102.526"
          y1="2912.008"
          y2="2699.837"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor={DARK}></stop>
          <stop offset="1" stopColor={MAIN}></stop>
        </linearGradient>
        <path
          fill="url(#SVGID_00000053507591712166881180000007460362432420398497_)"
          d="M1970.274 2444.002H2206.917V2994.489H1970.274z"
          clipPath="url(#SVGID_00000158731766686034618320000007877094560139081628_)"
        ></path>
        <defs>
          <path
            id="SVGID_00000139262927965276858010000016768894493757696907_"
            d="M2095.202 2709.891l6.845 315.109c169.905-116.723 121.193-512.332 52.581-597.507a863.293 863.293 0 01-17.734 10.026l-45.896 78.695 4.204 193.677z"
          ></path>
        </defs>
        <clipPath id="SVGID_00000147186226338310625400000000631675238109745553_">
          <use
            overflow="visible"
            xlinkHref="#SVGID_00000139262927965276858010000016768894493757696907_"
          ></use>
        </clipPath>
        <linearGradient
          id="SVGID_00000173159440413701001240000001291062466040573829_"
          x1="2050.537"
          x2="2188.039"
          y1="2868.358"
          y2="2719.124"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor={DARK}></stop>
          <stop offset="1" stopColor={MAIN}></stop>
        </linearGradient>
        <path
          fill="url(#SVGID_00000173159440413701001240000001291062466040573829_)"
          d="M2090.999 2427.493H2271.953V3025H2090.999z"
          clipPath="url(#SVGID_00000147186226338310625400000000631675238109745553_)"
        ></path>
        <defs>
          <path
            id="SVGID_00000009569260613995759330000002946273766197180044_"
            d="M2159.698 2684.49l73.183 286.607c130.529-142.794 1.975-494.095-78.84-557.387a778.462 778.462 0 01-7.119 6.617l-38.028 65.202 50.804 198.961z"
          ></path>
        </defs>
        <clipPath id="SVGID_00000119087922876421589730000005841546138126369716_">
          <use
            overflow="visible"
            xlinkHref="#SVGID_00000009569260613995759330000002946273766197180044_"
          ></use>
        </clipPath>
        <linearGradient
          id="SVGID_00000132801575335926850070000014439203954497199239_"
          x1="2180.389"
          x2="2244.346"
          y1="2825.363"
          y2="2672.864"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor={DARK}></stop>
          <stop offset="1" stopColor={MAIN}></stop>
        </linearGradient>
        <path
          fill="url(#SVGID_00000132801575335926850070000014439203954497199239_)"
          d="M2108.894 2413.709H2363.4089999999997V2971.0959999999995H2108.894z"
          clipPath="url(#SVGID_00000119087922876421589730000005841546138126369716_)"
        ></path>
        <g>
          <defs>
            <path
              id="SVGID_00000155125749539034089000000010165842960184865949_"
              d="M2190.867 2626.303l112.748 223.501c81.828-141.805-88.48-408.678-166.805-446.243l-1.378 2.012c7.015 8.475 11.49 14.753 11.49 14.753l-32.153 55.129 76.098 150.848z"
            ></path>
          </defs>
          <clipPath id="SVGID_00000008120509546434714200000001868128201878151325_">
            <use
              overflow="visible"
              xlinkHref="#SVGID_00000155125749539034089000000010165842960184865949_"
            ></use>
          </clipPath>
          <linearGradient
            id="SVGID_00000109718491760372383340000005307381953341512617_"
            x1="2267.065"
            x2="2240.849"
            y1="2741.63"
            y2="2563.939"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor={DARK}></stop>
            <stop offset="1" stopColor={MAIN}></stop>
          </linearGradient>
          <path
            fill="url(#SVGID_00000109718491760372383340000005307381953341512617_)"
            d="M2114.769 2403.561H2385.442V2849.804H2114.769z"
            clipPath="url(#SVGID_00000008120509546434714200000001868128201878151325_)"
          ></path>
        </g>
        <g>
          <defs>
            <path
              id="SVGID_00000127754554642245273800000005283786865264878742_"
              d="M1834.941 2016.394l-397.692 279.238c269.138 106.995 679.099-217.355 787.7-397.577-29.822-30.763-57.244-63.601-82.398-97.654l-307.61 215.993z"
            ></path>
          </defs>
          <clipPath id="SVGID_00000135655854637810332860000004259241327218362027_">
            <use
              overflow="visible"
              xlinkHref="#SVGID_00000127754554642245273800000005283786865264878742_"
            ></use>
          </clipPath>
          <linearGradient
            id="SVGID_00000101825676893928003190000001074214034819963776_"
            x1="1761.385"
            x2="1386.314"
            y1="2159.776"
            y2="2473.236"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor={MAIN}></stop>
            <stop offset="1" stopColor={DARK}></stop>
          </linearGradient>
          <path
            fill="url(#SVGID_00000101825676893928003190000001074214034819963776_)"
            d="M1437.249 1800.401H2224.949V2402.627H1437.249z"
            clipPath="url(#SVGID_00000135655854637810332860000004259241327218362027_)"
          ></path>
        </g>
        <g>
          <defs>
            <path
              id="SVGID_00000127752947492800540440000002858728084812468352_"
              d="M1586.22 1709.419l-548.02 100.329c251.864 264.301 930.788 67.03 1058.289-76.675-14.853-23.464-28.703-47.195-41.688-70.928l-32.875-32.494-435.706 79.768z"
            ></path>
          </defs>
          <clipPath id="SVGID_00000153704266738337989600000001093219172626121917_">
            <use
              overflow="visible"
              xlinkHref="#SVGID_00000127752947492800540440000002858728084812468352_"
            ></use>
          </clipPath>
          <linearGradient
            id="SVGID_00000078036013275918303260000007012665352213743275_"
            x1="1798.9"
            x2="981.842"
            y1="1880.613"
            y2="1779.118"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor={MAIN}></stop>
            <stop offset="1" stopColor={DARK}></stop>
          </linearGradient>
          <path
            fill="url(#SVGID_00000078036013275918303260000007012665352213743275_)"
            d="M1038.2 1629.65H2096.4880000000003V2074.0480000000002H1038.2z"
            clipPath="url(#SVGID_00000153704266738337989600000001093219172626121917_)"
          ></path>
        </g>
        <g>
          <defs>
            <path
              id="SVGID_00000016779520023049121430000006499891318056552615_"
              d="M1584.488 1539.697l-523.672-5.832c184.575 288.532 844.214 229.219 989.407 119.928-19.873-36.927-37.528-73.749-53.195-109.503l-412.54-4.593z"
            ></path>
          </defs>
          <clipPath id="SVGID_00000081632930428936553530000000547446830368590211_">
            <use
              overflow="visible"
              xlinkHref="#SVGID_00000016779520023049121430000006499891318056552615_"
            ></use>
          </clipPath>
          <linearGradient
            id="SVGID_00000122706182955062077330000013677445975703775934_"
            x1="1938.634"
            x2="980.606"
            y1="1738.543"
            y2="1587.473"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor={MAIN}></stop>
            <stop offset="1" stopColor={DARK}></stop>
          </linearGradient>
          <path
            fill="url(#SVGID_00000122706182955062077330000013677445975703775934_)"
            d="M1060.816 1533.864H2050.223V1822.396H1060.816z"
            clipPath="url(#SVGID_00000081632930428936553530000000547446830368590211_)"
          ></path>
        </g>
        <g>
          <defs>
            <path
              id="SVGID_00000080925943394504527710000008338073411661175958_"
              d="M2706.127 2073.986s153.465-16.152 253.619 92.078c0 0-14.541-93.692-53.31-121.156 66.833-174.003-135.926-315.196-320.205-284.799-294.45 48.577-535.832 707.548-514.968 950.344 197.888-183.114 564.879-294.047 634.864-636.467"
            ></path>
          </defs>
          <clipPath id="SVGID_00000094588149930961675590000013342346086437231744_">
            <use
              overflow="visible"
              xlinkHref="#SVGID_00000080925943394504527710000008338073411661175958_"
            ></use>
          </clipPath>
          <linearGradient
            id="SVGID_00000141414443548983049390000003857330542117876627_"
            x1="2499.383"
            x2="2528.454"
            y1="2560.524"
            y2="1765.705"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor={DARKER}></stop>
            <stop offset="1" stopColor={MAIN}></stop>
          </linearGradient>
          <path
            fill="url(#SVGID_00000141414443548983049390000003857330542117876627_)"
            d="M2050.4 1729.713H2973.271V2710.455H2050.4z"
            clipPath="url(#SVGID_00000094588149930961675590000013342346086437231744_)"
          ></path>
        </g>
        <path
          fill={DARKER}
          fillRule="evenodd"
          d="M2112.205 2426.218c86.165-181.128 327.924-213.114 458.63-216.643-102.667-8.293-191.442-38.653-267.739-81.815-18.797-10.634-36.837-22.045-54.143-34.093-.978-.68-1.952-1.362-2.926-2.047a1482.374 1482.374 0 00-16.057 30.88c-26.263 51.996-49.917 106.271-70.474 160.476-18.336 48.355-34.213 96.658-47.291 143.242"
          clipRule="evenodd"
        ></path>
        <defs>
          <filter
            id="Adobe_OpacityMaskFilter_00000020390715065175286470000009967886509597636015_"
            width="559.643"
            height="171.64"
            x="2351.472"
            y="1755.968"
            filterUnits="userSpaceOnUse"
          >
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"></feColorMatrix>
          </filter>
        </defs>
        <mask
          id="SVGID_00000133515233585430079940000011082863539805525644_"
          width="559.643"
          height="171.64"
          x="2351.472"
          y="1755.968"
          maskUnits="userSpaceOnUse"
        >
          <g filter="url(#Adobe_OpacityMaskFilter_00000020390715065175286470000009967886509597636015_)">
            <linearGradient
              id="SVGID_00000010308894298733934270000011546126322324285578_"
              x1="1051.182"
              x2="1016.5"
              y1="4053.15"
              y2="4644.369"
              gradientTransform="translate(1601.476 -2575.556)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#FFF"></stop>
              <stop offset="1"></stop>
            </linearGradient>
            <path
              fill="url(#SVGID_00000010308894298733934270000011546126322324285578_)"
              d="M2351.472 1755.968H2911.117V1927.6080000000002H2351.472z"
            ></path>
          </g>
        </mask>
        <path
          fill={LIGHTER}
          fillRule="evenodd"
          d="M2351.472 1927.608s286.059-180.109 559.644-3.066c-36.459-112.711-185.924-187.356-324.884-164.433-84.225 13.897-164.112 77.737-234.76 167.499"
          clipRule="evenodd"
          mask="url(#SVGID_00000133515233585430079940000011082863539805525644_)"
        ></path>
        <g>
          <defs>
            <path
              id="SVGID_00000025439282977501089930000003077082823575669166_"
              d="M2187.99 2081.28l-178.037 255.196c148.189 4.286 290.06-176.972 354.224-317.43-30.503-20.329-59.024-43.001-85.683-67.488l-90.504 129.722z"
            ></path>
          </defs>
          <clipPath id="SVGID_00000115485121127239884730000014983317546797430705_">
            <use
              overflow="visible"
              xlinkHref="#SVGID_00000025439282977501089930000003077082823575669166_"
            ></use>
          </clipPath>
          <linearGradient
            id="SVGID_00000022543697021069458200000002539097042619462813_"
            x1="2257.246"
            x2="1949.091"
            y1="2167.138"
            y2="2075.027"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor={MAIN}></stop>
            <stop offset="1" stopColor={DARK}></stop>
          </linearGradient>
          <path
            fill="url(#SVGID_00000022543697021069458200000002539097042619462813_)"
            d="M2009.953 1951.558H2364.176V2340.762H2009.953z"
            clipPath="url(#SVGID_00000115485121127239884730000014983317546797430705_)"
          ></path>
        </g>
        <defs>
          <filter
            id="Adobe_OpacityMaskFilter_00000061467265446249113570000014873929050227739276_"
            width="295.393"
            height="384.918"
            x="2009.953"
            y="1951.558"
            filterUnits="userSpaceOnUse"
          >
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"></feColorMatrix>
          </filter>
        </defs>
        <mask
          id="SVGID_00000094591286020263788230000015024105616809872278_"
          width="295.393"
          height="384.918"
          x="2009.953"
          y="1951.558"
          maskUnits="userSpaceOnUse"
        >
          <g filter="url(#Adobe_OpacityMaskFilter_00000061467265446249113570000014873929050227739276_)">
            <linearGradient
              id="SVGID_00000040558607740304252060000003547742625343633802_"
              x1="969.746"
              x2="-325.817"
              y1="4525.939"
              y2="5049.325"
              gradientTransform="translate(1567.995 -2535.473)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#FFF"></stop>
              <stop offset="1"></stop>
            </linearGradient>
            <path
              fill="url(#SVGID_00000040558607740304252060000003547742625343633802_)"
              d="M2009.953 1951.558H2305.346V2336.476H2009.953z"
            ></path>
          </g>
        </mask>
        <path
          fill={DARKER}
          fillRule="evenodd"
          d="M2187.99 2081.28l-178.037 255.196s221.294-138.867 295.393-361.414a743.612 743.612 0 01-26.852-23.504l-90.504 129.722z"
          clipRule="evenodd"
          mask="url(#SVGID_00000094591286020263788230000015024105616809872278_)"
        ></path>
        <g>
          <defs>
            <path
              id="SVGID_00000054972781157887438650000001611916555325431199_"
              d="M2037.536 2065.984l-266.271 273.707c202.114 39.158 441.597-211.007 528.055-373.854-30.841-24.65-59.573-51.61-86.276-80.264l-175.508 180.411z"
            ></path>
          </defs>
          <clipPath id="SVGID_00000075852791514918765800000008116321963303275907_">
            <use
              overflow="visible"
              xlinkHref="#SVGID_00000054972781157887438650000001611916555325431199_"
            ></use>
          </clipPath>
          <linearGradient
            id="SVGID_00000155113360022244027570000014952301219252509347_"
            x1="2019.592"
            x2="1734.898"
            y1="2155.624"
            y2="2580.166"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor={MAIN}></stop>
            <stop offset="1" stopColor={DARK}></stop>
          </linearGradient>
          <path
            fill="url(#SVGID_00000155113360022244027570000014952301219252509347_)"
            d="M1771.265 1885.573H2299.32V2378.849H1771.265z"
            clipPath="url(#SVGID_00000075852791514918765800000008116321963303275907_)"
          ></path>
        </g>
        <g>
          <defs>
            <path
              id="SVGID_00000054984043400911083650000014570600443955048873_"
              d="M1663.558 1892.83l-512.989 217.325c294.547 197.144 887.148-117.988 1006.702-290.302-20.717-26.724-40.004-54.292-57.9-82.291l-27.956-17.522-407.857 172.79z"
            ></path>
          </defs>
          <clipPath id="SVGID_00000092456260828496008050000006125903965094306463_">
            <use
              overflow="visible"
              xlinkHref="#SVGID_00000054984043400911083650000014570600443955048873_"
            ></use>
          </clipPath>
          <linearGradient
            id="SVGID_00000113316439038703644440000015935332398520127899_"
            x1="1744.475"
            x2="1093.889"
            y1="1961.435"
            y2="2336.711"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor={MAIN}></stop>
            <stop offset="1" stopColor={DARK}></stop>
          </linearGradient>
          <path
            fill="url(#SVGID_00000113316439038703644440000015935332398520127899_)"
            d="M1150.568 1720.04H2157.27V2307.299H1150.568z"
            clipPath="url(#SVGID_00000092456260828496008050000006125903965094306463_)"
          ></path>
        </g>
        <g>
          <defs>
            <path
              id="SVGID_00000002345358902631177750000015792515242722940803_"
              d="M1635.094 1385.815l-457.158-91.436c110.173 273.587 662.837 335.739 830.164 274.653-18.83-41.066-34.998-80.999-48.796-118.376l-324.21-64.841z"
            ></path>
          </defs>
          <clipPath id="SVGID_00000011019809995428756600000017221110836432070319_">
            <use
              overflow="visible"
              xlinkHref="#SVGID_00000002345358902631177750000015792515242722940803_"
            ></use>
          </clipPath>
          <linearGradient
            id="SVGID_00000177450197121881949370000013749842960996336547_"
            x1="1889.985"
            x2="1256.536"
            y1="1638.517"
            y2="1262.527"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor={MAIN}></stop>
            <stop offset="1" stopColor={DARK}></stop>
          </linearGradient>
          <path
            fill="url(#SVGID_00000177450197121881949370000013749842960996336547_)"
            d="M1177.936 1294.38H2008.1V1630.1190000000001H1177.936z"
            clipPath="url(#SVGID_00000011019809995428756600000017221110836432070319_)"
          ></path>
        </g>
        <g>
          <defs>
            <path
              id="SVGID_00000087372437029641336340000016270974619598618502_"
              d="M2351.472 1927.608c-127.405-90.367-261.945-235.756-352.908-469.1l-484.622-96.924s185.974 168.868 381.239 226.156c0 0 93.204 322.535 350.848 503.88 107.021 75.326 242.418 126.291 411.291 119.659 21.727-41.385 38.495-86.826 48.808-137.293-.001 0-169.825-15.286-354.656-146.378"
            ></path>
          </defs>
          <clipPath id="SVGID_00000007417788564513817550000007392421452564844696_">
            <use
              overflow="visible"
              xlinkHref="#SVGID_00000087372437029641336340000016270974619598618502_"
            ></use>
          </clipPath>
          <linearGradient
            id="SVGID_00000021095190494587889900000009273778048915633846_"
            x1="2322.47"
            x2="1927.1"
            y1="2105.003"
            y2="1518.272"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor={MAIN}></stop>
            <stop offset="1" stopColor={DARK}></stop>
          </linearGradient>
          <path
            fill="url(#SVGID_00000021095190494587889900000009273778048915633846_)"
            d="M1513.941 1361.584H2706.127V2217.91H1513.941z"
            clipPath="url(#SVGID_00000007417788564513817550000007392421452564844696_)"
          ></path>
        </g>
        <defs>
          <filter
            id="Adobe_OpacityMaskFilter_00000119103853961937616970000016565084174877829517_"
            width="1192.186"
            height="749.206"
            x="1513.941"
            y="1361.584"
            filterUnits="userSpaceOnUse"
          >
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"></feColorMatrix>
          </filter>
        </defs>
        <mask
          id="SVGID_00000101817638439335051270000007324990261686353583_"
          width="1192.186"
          height="749.206"
          x="1513.941"
          y="1361.584"
          maskUnits="userSpaceOnUse"
        >
          <g filter="url(#Adobe_OpacityMaskFilter_00000119103853961937616970000016565084174877829517_)">
            <linearGradient
              id="SVGID_00000014629616696780953660000003333558358634709410_"
              x1="35.768"
              x2="963.807"
              y1="3806.406"
              y2="4621.524"
              gradientTransform="translate(1519.369 -2557.598)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#FFF"></stop>
              <stop offset="1"></stop>
            </linearGradient>
            <path
              fill="url(#SVGID_00000014629616696780953660000003333558358634709410_)"
              d="M1513.941 1361.584H2706.127V2110.79H1513.941z"
            ></path>
          </g>
        </mask>
        <path
          fill={DARKER}
          fillRule="evenodd"
          d="M2351.472 1927.608c-127.405-90.367-261.945-235.756-352.908-469.1l-484.622-96.924s252.732 67.81 447.996 125.1c0 0 93.207 322.535 350.851 503.875 101.288 71.294 228 120.755 384.449 120.227a532.706 532.706 0 008.889-36.8s-169.824-15.286-354.655-146.378"
          clipRule="evenodd"
          mask="url(#SVGID_00000101817638439335051270000007324990261686353583_)"
        ></path>
        <defs>
          <filter
            id="Adobe_OpacityMaskFilter_00000101807355397430224820000015564748255904726949_"
            width="842.769"
            height="115.823"
            x="1060.816"
            y="1533.864"
            filterUnits="userSpaceOnUse"
          >
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"></feColorMatrix>
          </filter>
        </defs>
        <mask
          id="SVGID_00000136385818371316648000000009713657634107681202_"
          width="842.769"
          height="115.823"
          x="1060.816"
          y="1533.864"
          maskUnits="userSpaceOnUse"
        >
          <g filter="url(#Adobe_OpacityMaskFilter_00000101807355397430224820000015564748255904726949_)">
            <linearGradient
              id="SVGID_00000119810010176754597410000017485744194093798579_"
              x1="510.444"
              x2="-519.394"
              y1="3991.479"
              y2="4407.146"
              gradientTransform="translate(1474.947 -2602.802)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#FFF"></stop>
              <stop offset="1"></stop>
            </linearGradient>
            <path
              fill="url(#SVGID_00000119810010176754597410000017485744194093798579_)"
              d="M1060.816 1533.864H1903.585V1649.688H1060.816z"
            ></path>
          </g>
        </mask>
        <path
          fill={DARKER}
          fillRule="evenodd"
          d="M1060.816 1533.864s401.784 194.399 842.769 79.494c-4.525-12.912-7.151-21.43-8.05-24.42-108.453 6.888-255.802-7.982-390.23-50.123l-444.489-4.951z"
          clipRule="evenodd"
          mask="url(#SVGID_00000136385818371316648000000009713657634107681202_)"
        ></path>
        <defs>
          <filter
            id="Adobe_OpacityMaskFilter_00000118394367007263220440000003160033759993461144_"
            width="925.334"
            height="129.682"
            x="1038.2"
            y="1702.757"
            filterUnits="userSpaceOnUse"
          >
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"></feColorMatrix>
          </filter>
        </defs>
        <mask
          id="SVGID_00000048484137458061993180000017923849222190508173_"
          width="925.334"
          height="129.682"
          x="1038.2"
          y="1702.757"
          maskUnits="userSpaceOnUse"
        >
          <g filter="url(#Adobe_OpacityMaskFilter_00000118394367007263220440000003160033759993461144_)">
            <linearGradient
              id="SVGID_00000088851447308747289040000011914186613502872995_"
              x1="842.093"
              x2="-453.471"
              y1="4023.659"
              y2="4547.046"
              gradientTransform="translate(1472.73 -2584.886)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#FFF"></stop>
              <stop offset="1"></stop>
            </linearGradient>
            <path
              fill="url(#SVGID_00000088851447308747289040000011914186613502872995_)"
              d="M1038.2 1702.757H1963.534V1832.439H1038.2z"
            ></path>
          </g>
        </mask>
        <path
          fill={DARKER}
          fillRule="evenodd"
          d="M1939.801 1702.757c-123.316 36.063-310.452 53.996-484.383 30.606l-417.218 76.384s553.233 79.066 925.334-57.98a1184.41 1184.41 0 01-23.733-49.01"
          clipRule="evenodd"
          mask="url(#SVGID_00000048484137458061993180000017923849222190508173_)"
        ></path>
        <defs>
          <filter
            id="Adobe_OpacityMaskFilter_00000175281633090120736030000008197440148884748674_"
            width="865.604"
            height="304.131"
            x="1150.568"
            y="1806.024"
            filterUnits="userSpaceOnUse"
          >
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"></feColorMatrix>
          </filter>
        </defs>
        <mask
          id="SVGID_00000103966061466022965810000001985200549049286532_"
          width="865.604"
          height="304.131"
          x="1150.568"
          y="1806.024"
          maskUnits="userSpaceOnUse"
        >
          <g filter="url(#Adobe_OpacityMaskFilter_00000175281633090120736030000008197440148884748674_)">
            <linearGradient
              id="SVGID_00000017492309173380023560000012760326807577869696_"
              x1="908.843"
              x2="-386.72"
              y1="4188.838"
              y2="4712.225"
              gradientTransform="translate(1483.746 -2557.66)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#FFF"></stop>
              <stop offset="1"></stop>
            </linearGradient>
            <path
              fill="url(#SVGID_00000017492309173380023560000012760326807577869696_)"
              d="M1150.568 1806.022H2016.173V2110.156H1150.568z"
            ></path>
          </g>
        </mask>
        <path
          fill={DARKER}
          fillRule="evenodd"
          d="M1150.568 2110.156s507.095-37.671 865.604-266.402a1112.929 1112.929 0 01-22.972-37.729c-102.91 53.712-257.679 103.285-416.955 123.798l-425.677 180.333z"
          clipRule="evenodd"
          mask="url(#SVGID_00000103966061466022965810000001985200549049286532_)"
        ></path>
        <defs>
          <filter
            id="Adobe_OpacityMaskFilter_00000122701932912933359270000004766658898697415559_"
            width="645.833"
            height="382.742"
            x="1437.249"
            y="1912.89"
            filterUnits="userSpaceOnUse"
          >
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"></feColorMatrix>
          </filter>
        </defs>
        <mask
          id="SVGID_00000108283714895149058130000001163599369126756785_"
          width="645.833"
          height="382.742"
          x="1437.249"
          y="1912.89"
          maskUnits="userSpaceOnUse"
        >
          <g filter="url(#Adobe_OpacityMaskFilter_00000122701932912933359270000004766658898697415559_)">
            <linearGradient
              id="SVGID_00000081634954444206307580000000037794886759902601_"
              x1="956.286"
              x2="-339.275"
              y1="4357.73"
              y2="4881.115"
              gradientTransform="translate(1511.85 -2539.477)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#FFF"></stop>
              <stop offset="1"></stop>
            </linearGradient>
            <path
              fill="url(#SVGID_00000081634954444206307580000000037794886759902601_)"
              d="M1437.249 1912.89H2083.082V2295.632H1437.249z"
            ></path>
          </g>
        </mask>
        <path
          fill={DARKER}
          fillRule="evenodd"
          d="M1437.249 2295.632s329.888-32.243 645.833-358.55a953.421 953.421 0 01-18.925-24.191c-89.239 70.923-221.478 146.276-363.084 197.497l-263.824 185.244z"
          clipRule="evenodd"
          mask="url(#SVGID_00000108283714895149058130000001163599369126756785_)"
        ></path>
        <defs>
          <filter
            id="Adobe_OpacityMaskFilter_00000000220817219236440480000015695017119990343089_"
            width="403.86"
            height="336.396"
            x="1771.265"
            y="2003.295"
            filterUnits="userSpaceOnUse"
          >
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"></feColorMatrix>
          </filter>
        </defs>
        <mask
          id="SVGID_00000070089386830910875030000007831813068466291118_"
          width="403.86"
          height="336.396"
          x="1771.265"
          y="2003.295"
          maskUnits="userSpaceOnUse"
        >
          <g filter="url(#Adobe_OpacityMaskFilter_00000000220817219236440480000015695017119990343089_)">
            <linearGradient
              id="SVGID_00000154426757760120033460000014690806838145027487_"
              x1="976.842"
              x2="-318.721"
              y1="4485.127"
              y2="5008.615"
              gradientTransform="translate(1544.596 -2535.158)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#FFF"></stop>
              <stop offset="1"></stop>
            </linearGradient>
            <path
              fill="url(#SVGID_00000154426757760120033460000014690806838145027487_)"
              d="M1771.265 2003.295H2175.125V2339.6910000000003H1771.265z"
            ></path>
          </g>
        </mask>
        <path
          fill={DARKER}
          fillRule="evenodd"
          d="M1771.265 2339.691s238.355-114.21 403.86-304.863a807.97 807.97 0 01-32.947-31.533c-58.573 62.144-136.166 127.944-221.812 183.13l-149.101 153.266z"
          clipRule="evenodd"
          mask="url(#SVGID_00000070089386830910875030000007831813068466291118_)"
        ></path>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/" aria-label="Homepage">{logo}</RouterLink>;
}
