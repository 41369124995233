import { CircularProgress, Stack, Typography } from "@mui/material";

export default function Spinner({text = false, color = "primary", size=32, sx}){
    return (<>
        <Stack direction="row" sx={{p: 2, width: "100%", height: "100%", ...sx}} alignContent="center" justifyContent="center">
            <Stack direction="column" sx={{textAlign: "center"}} alignContent="center" justifyContent="center" alignItems="center">
                <CircularProgress color={color} size={size}/>
                {text &&
                    <Typography variant="caption" sx={{color: 'text.secondary', mt: 2}}>{text}</Typography>
                }
            </Stack>
        </Stack>
    </>)
}