/* eslint-disable */
import ServerUtils from "./helpers";

export default class UserUtils {
  static async getAll() {
    return ServerUtils.GET("api/users/")
  }
  static async getAllForGameId(gameId) {
    return ServerUtils.GET(`api/users/game/${gameId}`)
  }
  static async update(user) {
    return ServerUtils.POST('api/users/update', {
      user: user
    })
  }
  static async updateImage(user) {
    return ServerUtils.POST('api/users/updateImage', {
      user: user
    })
  }
  static async updateBanner(user) {
    return ServerUtils.POST('api/users/updateBanner', {
      user: user
    })
  }
  static async remove(userId) {
    return ServerUtils.POST('api/users/delete', {
      id: userId,
    })
  }
  static async getProfile(userId) {
    return ServerUtils.GET(`api/users/${userId}/profile`)
  }
  static async changePassword(user, newPassword) {
    return ServerUtils.POST('api/users/update/password',
      {
        user: user,
        newPassword: newPassword,
      })
  }
  static async getMessages(userId) {
    return ServerUtils.GET(`api/messages/${userId}`)
  }
  static async resetPassword(email) {
    return ServerUtils.POST('api/users/reset-password', { email: email })
  }
  static async sendNewWebsiteEmail(email) {
    return ServerUtils.POST('api/users/send-new-website-email', { email: email })
  }
  static async validatePasswordResetToken(token) {
    return ServerUtils.POST('api/users/check-password-token', { token: token })
  }
  static async submitPasswordReset(token, newPassword) {
    return ServerUtils.POST('api/users/submit-reset-password', { token: token, newPassword: newPassword })
  }
  static async getKanban() {
    return ServerUtils.GET('api/users/kanban');
  }
  static async updateKanban(kanban) {
    return ServerUtils.POST('api/users/kanban/update', {kanban: kanban});
  }
  static async submitTicket(ticket) {
    return ServerUtils.POST('api/users/submit-ticket', { ticket: ticket })
  }
  static async getTickets() {
    return ServerUtils.POST('api/users/get-tickets')
  }
  static async getLoginsForUserId(userId) {
    return ServerUtils.GET(`api/users/${userId}/logins`);
  }
  static async getAllLogins() {
    return ServerUtils.GET(`api/users/logins/all`);
  }
  static async getAllPostsByUserId(userId){
    return ServerUtils.GET(`api/users/posts/user/${userId}`)
  }
  static async getAllPosts(){
    return ServerUtils.GET(`api/users/posts/all`)
  }
  static async getAllFriendsPosts(){
    return ServerUtils.GET(`api/users/posts/friends`)
  }
  static async addPost(post) {
    return ServerUtils.POST('api/users/posts/add', { post: post })
  }
  static async getStaff() {
    return ServerUtils.GET('api/staff/employees/get-all');
  }
  static async importLegacy(data) {
    return ServerUtils.POST('api/users/import-legacy', {
      data: data
    })
  }
  static async importEmployees(data) {
    return ServerUtils.POST('api/users/import-employees', {
      data: data
    })
  }
}