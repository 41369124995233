import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { List, Box, ListSubheader } from '@mui/material';
import useAuth from '../../../hooks/useAuth';
//
import { NavListRoot } from './NavList';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSectionVertical({ navConfig, isCollapse = false, ...other }) {
  const { user } = useAuth();
  const hasRole = (role) => {
    if (!role) return true;
    if (!user.role) return false;
    if (role === "admin"){
      return user.role === "admin" || user.role === "developer"
    }
    if (role === "referral"){
      return user.role === "referral" || user.role === "admin" || user.role === "developer"
    }
    if (role === "provider"){
      return user.role === "provider" || user.role === "admin" || user.role === "developer"
    }
    if (role === "employee"){
      return user.role === "employee" || user.role === "admin" || user.role === "developer"
    }
    return user.role === role
  }
  
  return (
    <Box {...other}>
      {navConfig.map((group) => hasRole(group.role) ? (
        <List key={group.subheader} disablePadding sx={{ px: 2 }}>

          <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0,
              }),
            }}
          >
            {group.subheader}
          </ListSubheaderStyle>

          {group.items.map((list) => (
            <>
            {hasRole(list.role) ? 
              <NavListRoot key={list.title} list={list} isCollapse={isCollapse} />
              :
              <></>
            }
            </>
          ))}
        </List>
      ) : (<></>))}
    </Box>
  );
}
