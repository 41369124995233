import { Alert, AlertTitle, Container } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import useAuth from '../hooks/useAuth';

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  accessibleRoles: PropTypes.array, // Example ['admin', 'leader']
  children: PropTypes.node
};

export default function RoleBasedGuard({ accessibleRoles, children }) {
  const { user } = useAuth();
  const navigate = useNavigate();

  if (!accessibleRoles.includes(user.role)) {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle >Permission Denied</AlertTitle>
          You do not have permission to access this page. You need one of the following role(s): {accessibleRoles.join(', ')}
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
}
