// routes
import { PATH_ADMIN, PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';
import Label from '../../../components/Label';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  developer: getIcon('ic_developer'),
  admin: getIcon('ic_admin'),
  user: getIcon('ic_user'),
  profile: getIcon('ic_profile'),
  messages: getIcon('ic_messages'),
  settings: getIcon('ic_settings'),
  calendar: getIcon('ic_calendar'),
  home: getIcon('ic_home'),
  overview: getIcon('ic_overview'),
  administration: getIcon('ic_administration'),
  search: getIcon('ic_search'),
  checklist: getIcon('ic_checklist'),
  clipboard: getIcon('ic_clipboard'),
  battle: getIcon('ic_battle'),
  briefcase: getIcon('ic_briefcase'),
  clipboardArrow: getIcon('ic_clipboard_arrow'),
  clipboardPlus: getIcon('ic_clipboard_plus'),
  invoice: getIcon('ic_invoice'),
  bill: getIcon('ic_bill'),
  games: getIcon('ic_games'),
  plinko: getIcon('ic_plinko'),
};

const navConfig = (userId, notificationCount = 0) => {
  return [
    {
      subheader: 'Dashboard',
      items: [
        {
          title: 'Home',
          path: PATH_DASHBOARD.home.root,
          icon: ICONS.home,
        },
      ],
    },
    {
      subheader: 'Games',
      items: [
        {
          title: 'Cases',
          path: PATH_DASHBOARD.games.cases.root,
          icon: ICONS.briefcase,
          role: "developer",
          children: [
            {
              title: 'Battles',
              path: PATH_DASHBOARD.games.battles.root,
            },
            {
              title: 'Browse All Cases',
              path: PATH_DASHBOARD.games.cases.browse,
            },
            {
              title: 'My Inventory',
              path: PATH_DASHBOARD.games.cases.inventory,
            },
          ]
        },
        {
          title: 'Plinko',
          path: PATH_DASHBOARD.games.plinko,
          icon: ICONS.plinko,
        },
      ],
    },
    {
      subheader: 'My Account',
      items: [
        {
          title: 'Freelancing',
          path: PATH_DASHBOARD.freelancing.root,
          icon: ICONS.clipboard,
          role: "developer",
          children: [
            { title: 'Overview', path: PATH_DASHBOARD.freelancing.overview, },
            { title: 'Invoices', path: PATH_DASHBOARD.freelancing.invoices.root },
            { title: 'Bills', path: PATH_DASHBOARD.freelancing.bills.root },
            { title: 'Clients', path: PATH_DASHBOARD.freelancing.clients.root }
          ]
        },
        {
          title: 'Admin',
          path: PATH_ADMIN.root,
          icon: ICONS.admin,
          role: "developer",
          children: [
            { title: 'Management', path: PATH_ADMIN.management },
            { title: 'Administration', path: PATH_ADMIN.administration },
            { title: 'kanban', path: PATH_ADMIN.kanban },
          ]
        },
        {
          title: 'Settings',
          path: PATH_DASHBOARD.user.account,
          icon: ICONS.settings,
        },
      ],
    },
  ];
}

export const navConfigHorizontal = () => {
  return [
    {
      title: 'Home',
      path: PATH_DASHBOARD.home.root,
      icon: ICONS.home,
    },
    {
      title: 'Games',
      path: PATH_DASHBOARD.games.root,
      icon: ICONS.games,
      items: [
        {
          title: 'Battles',
          path: PATH_DASHBOARD.games.battles.root,
          icon: ICONS.battle,
        },
        {
          title: 'Browse Cases',
          path: PATH_DASHBOARD.games.cases.browse,
          icon: ICONS.briefcase,
        },
        {
          title: 'My Inventory',
          path: PATH_DASHBOARD.games.cases.inventory,
          icon: ICONS.battle,
        },
        {
          title: 'Plinko',
          path: PATH_DASHBOARD.games.plinko,
          icon: ICONS.plinko,
        },
      ],
    },
    {
      title: 'Freelancing',
      icon: ICONS.clipboard,
      path: PATH_DASHBOARD.freelancing.root,
      role: "developer",
      items: [
        {
          title: 'Overview',
          path: PATH_DASHBOARD.freelancing.overview,
          icon: ICONS.overview,
        },
        {
          title: 'Invoices',
          path: PATH_DASHBOARD.freelancing.invoices.root,
          icon: ICONS.checklist,
        },
        {
          title: 'Bills',
          path: PATH_DASHBOARD.freelancing.bills.root,
          icon: ICONS.bill,
        },
        {
          title: 'Clients',
          path: PATH_DASHBOARD.freelancing.clients.root,
          icon: ICONS.user,
        },
      ],
    },
    {
      title: 'My Account',
      icon: ICONS.profile,
      path: PATH_DASHBOARD.user.root,
      items: [
        {
          title: 'Settings',
          path: PATH_DASHBOARD.user.account,
          icon: ICONS.settings,
        },
      ],
    },
    {
      title: 'Admin',
      icon: ICONS.developer,
      role: 'developer',
      path: PATH_ADMIN.root,
      items: [
        {
          title: 'Management',
          path: PATH_ADMIN.management,
          icon: ICONS.admin,
        },
        {
          title: 'Administration',
          path: PATH_ADMIN.administration,
          icon: ICONS.administration,
        },
        {
          title: 'Kanban',
          path: PATH_ADMIN.kanban, 
          icon: ICONS.calendar,
        },
      ],
    },
  ];
}

export default navConfig;
