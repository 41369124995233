/* eslint-disable */

import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
// @mui
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { useSnackbar } from 'notistack';
// utils
// components
import { LoadingButton } from '@mui/lab';
import { FixedSizeList } from 'react-window';
import CaseUtils from '../../../api_utils/cases';
import Iconify from '../../../components/Iconify';
import MenuPopover from '../../../components/MenuPopover';
import Spinner from '../../../components/Spinner';
import { NumberAnimate } from '../../../components/animate';
import useAuth from '../../../hooks/useAuth';
import cssStyles from '../../../utils/cssStyles';
import { fCurrency } from '../../../utils/formatNumber';
import { PATH_DASHBOARD } from '../../../routes/paths';
import Label from '../../../components/Label';

// ----------------------------------------------------------------------

export default function BalancePopover() {
  const theme = useTheme();
  const [inventoryOpen, setInventoryOpen] = useState(null);
  const [walletOpen, setWalletOpen] = useState(null);
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [selling, setSelling] = useState(false);
  const [inventory, setInventory] = useState([]);
  const [inventoryLastUpdated, setInventoryLastUpdated] = useState(0);
  const [selectedSkins, setSelectedSkins] = useState([]);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar()
  const handleOpenInventory = (event) => setInventoryOpen(event.currentTarget)
  const handleCloseInventory = () => setInventoryOpen(null);
  const handleOpenWallet = (event) => setWalletOpen(event.currentTarget)
  const handleCloseWallet = () => setWalletOpen(null);

  const loadInventory = async () => {
    if (!user) return;

    setLoading(true);
    if (user && user.id !== undefined) {
      const res = await CaseUtils.getInventory(user.id);
      if (res.success) {
        setInventory(res.data.items);
        setInventoryLastUpdated(res.data.lastUpdated);
        setSelectedSkins([]);
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    if (Boolean(inventoryOpen)) {
      loadInventory();
    }
  }, [inventoryOpen])

  useEffect(() => {
    loadInventory();
  }, [])

  function renderInventoryRow(props) {
    const { index, style } = props;

    const item = inventory[index];

    return (
      <ListItem style={style} key={`user-inventory-item-${index}`} component="div" disablePadding>
        <ListItemButton onClick={() => {
          if (selectedSkins.indexOf(item) === -1) {
            setSelectedSkins(prev => [...prev, item])
          } else {
            setSelectedSkins(prev => prev.filter(x => x.id !== item.id))
          }
        }}>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={selectedSkins.indexOf(item) !== -1}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>

          <ListItemText id={item.id} primary={
            <Stack direction="row" justifyContent="space-between">
              <img src={item.imageUrl} style={{ height: 64, width: 64, objectFit: 'contain' }} />
              <Stack sx={{ height: '100%' }} direction="row" justifyContent="end" alignContent="center" alignItems="center">
                <Stack>
                  <Typography variant='caption' sx={{ color: 'text.secondary' }} textAlign="end">
                    {item.variation === "stattrak" && <>StatTrak<sup>{'\u2122'}</sup>&nbsp;</>}
                    {item.wear}
                  </Typography>
                  <Typography variant='subtitle2' textAlign="end">
                    {item.name}
                  </Typography>
                  <Typography variant='caption' color="primary" textAlign="end">
                    {fCurrency(item.price)}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          } />
        </ListItemButton>
      </ListItem>
    );
  }

  const onSellSelected = async () => {
    if (selling) return false;
    setSelling(true);
    const res = await CaseUtils.sellSkins(selectedSkins, user.id);
    if (res.success) {
      enqueueSnackbar(`Successfully Sold ${selectedSkins.length} Skins for ${fCurrency(selectedSkinsValue)}`)
      loadInventory();
    } else {
      enqueueSnackbar(`Error selling skins: ${res.message}`, { variant: 'warning' })
    }
    setSelling(false);
  }

  const onSelectAll = async () => {
    setSelectedSkins(inventory);
  }

  const selectedSkinsValue = useMemo(() => {
    let value = 0;
    if (!selectedSkins || selectedSkins.length === 0) return value;
    try {
      let priceMap = selectedSkins.map(item => item.price);
      value = priceMap.reduce((prev, next) => prev + next)
    } catch (er) { }
    return value
  }, [selectedSkins])

  const inventoryValue = useMemo(() => {
    let value = 0;
    if (!inventory || inventory.length === 0) return value;
    try {
      let priceMap = inventory.map(item => item.price);
      value = priceMap.reduce((prev, next) => prev + next)
    } catch (er) {
      console.log(er);
    }
    console.log('inventoryValue', value)
    return value
  }, [inventory, user])

  const userTotalValue = useMemo(() => {
    let value = 0;
    let inventorySnapshot = {
      value: 0,
      lastUpdated: 0,
    };
    if (user) {
      value += Number(user.balance);
      if (user.inventorySnapshot) {
        inventorySnapshot = user.inventorySnapshot;
      }
    }
    if (inventoryLastUpdated > 0 || inventorySnapshot.lastUpdated > 0) {
      if (inventorySnapshot.lastUpdated > inventoryLastUpdated) {
        value += Number(inventorySnapshot.value)
      } else {
        if (inventory.length > 0) {
          value += inventory.map(item => item.price).reduce((prev, next) => prev + next)
        }
      }
    }
    console.log('userTotalValue', value)
    return value;
  }, [user, inventoryLastUpdated])

  return (
    <>
      <Card variant='outlined' sx={{
        borderRadius: 1,
        boxShadow: 'none',
        cursor: 'pointer',
        ...cssStyles().bgBlur({ blur: 64, color: theme.palette.background.paper, opacity: .33 }),
      }} >
        <Stack direction="row" justifyContent="space-between" alignContent="center" alignItems="center">
          <Button
            sx={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              px: 2
            }}
            onClick={handleOpenInventory}
            endIcon={<Iconify icon={`mdi:chevron-${Boolean(inventoryOpen) ? 'up' : 'down'}`} width={20} height={20} sx={{ color: 'primary.main' }} />}
          >
            <Typography variant='subtitle2' color="primary">
              <strong><NumberAnimate value={userTotalValue} formatter={fCurrency} /></strong>
            </Typography>
          </Button>
          <Button
            variant='contained'
            sx={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
            onClick={handleOpenWallet}
          >
            Wallet
          </Button>
        </Stack>
      </Card>
      <MenuPopover
        open={Boolean(inventoryOpen)}
        anchorEl={inventoryOpen}
        onClose={handleCloseInventory}
        sx={{ width: 400, p: 0, mt: 1.5, overflow: 'hidden' }}
      >
        {loading ?
          <Spinner text={"Loading Inventory..."} />
          :
          <>
            <Stack direction="row" justifyContent="space-between" alignItems="center" alignContent="center" sx={{ py: 1, px: 2 }}>
              <Stack direction="row" alignItems="center" alignContent="center" spacing={1}>
                <Typography variant="h6" textAlign="center">My Inventory</Typography>
                <IconButton size='small' color='primary' onClick={() => {
                  navigate(PATH_DASHBOARD.cases.myInventory)
                  handleCloseInventory();
                }}>
                  <Iconify icon={"mdi:eye"} />
                </IconButton>
              </Stack>
              <Stack>
                <Typography variant="caption" sx={{ color: 'text.secondary' }} textAlign="end">
                  {inventory.length} Item(s)
                </Typography>
                <Typography variant="caption" textAlign="end" color="primary">
                  <strong>{fCurrency(inventoryValue)}</strong>
                </Typography>
              </Stack>
            </Stack>
            {inventory.length > 0 &&
              <>
                <Divider sx={{ borderStyle: 'dashed' }} />
                <FixedSizeList
                  className='custom-scrollbar'
                  height={inventory.length > 0 ? inventory.length > 4 ? 400 : inventory.length * 80 : 0}
                  width={'100%'}
                  itemSize={80}
                  itemCount={inventory.length}
                  overscanCount={5}
                >
                  {renderInventoryRow}
                </FixedSizeList>
                <Divider sx={{ borderStyle: 'dashed' }} />
                <Stack direction="row" justifyContent="space-between">
                  {(inventory.length > 0 && selectedSkins.length !== inventory.length) &&
                    <Button variant='contained' fullWidth onClick={onSelectAll} sx={{ borderRadius: 0 }}>
                      Select All
                    </Button>
                  }
                  {selectedSkins.length > 0 &&
                    <LoadingButton variant='contained' fullWidth onClick={onSellSelected} disabled={selectedSkins.length == 0} sx={{ borderRadius: 0 }} loading={loading || selling}>
                      Sell ({fCurrency(selectedSkinsValue)})
                    </LoadingButton>
                  }
                </Stack>
              </>
            }
          </>
        }

      </MenuPopover>
      <MenuPopover
        open={Boolean(walletOpen)}
        anchorEl={walletOpen}
        onClose={handleCloseWallet}
        sx={{ width: 300, p: 0, mt: 1.5, overflow: 'hidden' }}
      >
        <Stack sx={{ py: 1, px: 2 }}>
          <Typography variant="h6" textAlign="center">My Wallet</Typography>
        </Stack>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <Stack sx={{ py: 1, px: 2 }} spacing={2}>
          <Stack direction="row" justifyContent="space-between" alignContent="center" alignItems="center">
            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }} textAlign="center">
              Balance
            </Typography>
            <Label color='success'>
              {fCurrency(user.balance)}
            </Label>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignContent="center" alignItems="center">
            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }} textAlign="center">
              Inventory Value
            </Typography>
            <Label color='primary'>
              {fCurrency(inventoryValue)}
            </Label>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignContent="center" alignItems="center">
            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }} textAlign="center">
              Stash
            </Typography>
            <Label color='info'>
              {fCurrency(user.stash ? user.stash : 0)}
            </Label>
          </Stack>
        </Stack>
        {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}
      </MenuPopover>
    </>
  );
}