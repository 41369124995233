import { useLocation, Outlet } from 'react-router-dom';
// @mui
import { Box, Link, Container, Typography, Stack } from '@mui/material';
// components
import Logo from '../../components/Logo';
//
import MainFooter from './MainFooter';
import MainHeader from './MainHeader';

// ----------------------------------------------------------------------

export default function MainLayout({ includeFooter = true }) {
  const { pathname } = useLocation();

  const isHome = pathname === '/';

  return (
    <div className='noScroll'>
      <Stack sx={{ minHeight: 1 }}>
        <MainHeader />

        <Outlet />

        <Box sx={{ flexGrow: 1 }} />
        {includeFooter &&
          <Box
            sx={{
              py: 5,
              // pt: 10,
              textAlign: 'center',
              position: 'relative',
              bgcolor: 'background.default',
            }}
          >
            <Container>
              <Logo sx={{ mb: 1, mx: 'auto', width: 50, height: 50 }} />

              <Typography variant="caption" component="p">
                Copyright © Ethan Blank - All rights reserved
              </Typography>
            </Container>
          </Box>
        }
      </Stack>
    </div>
  );
}
