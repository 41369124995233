import { createSlice } from '@reduxjs/toolkit';
import omit from 'lodash/omit';
//
import { dispatch } from '../store';
import { UserUtils } from '../../api_utils';
import uuidv4 from '../../utils/uuidv4';

// ----------------------------------------------------------------------

function objFromArray(array, key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
  isLoading: false,
  error: null,
  board: {
    cards: {},
    columns: {},
    columnOrder: [],
  },
};

const slice = createSlice({
  name: 'kanban',
  initialState,
  reducers: {
    updateBoard(state){
      const board = JSON.parse(JSON.stringify(state.board));
      UserUtils.updateKanban(board);
    },
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET BOARD
    getBoardSuccess(state, action) {
      state.isLoading = false;
      state.board = action.payload
    },

    // CREATE NEW COLUMN
    createColumnSuccess(state, action) {
      const newColumn = action.payload;
      state.isLoading = false;
      state.board.columns = {
        ...state.board.columns,
        [newColumn.id]: newColumn,
      };
      state.board.columnOrder.push(newColumn.id);
    },

    persistCard(state, action) {
      const columns = action.payload;
      state.board.columns = columns;
    },

    persistColumn(state, action) {
      state.board.columnOrder = action.payload;
    },

    addTask(state, action) {
      const { card, columnId } = action.payload;

      state.board.cards[card.id] = card;
      state.board.columns[columnId].cardIds.push(card.id);
    },

    updateTask(state, action) {
      const { card, columnId } = action.payload;
      state.board.cards[card.id] = card;
      //  state.board.columns[columnId].cardIds.push(card.id);
    },

    deleteTask(state, action) {
      const { cardId, columnId } = action.payload;

      state.board.columns[columnId].cardIds = state.board.columns[columnId].cardIds.filter((id) => id !== cardId);
      state.board.cards = omit(state.board.cards, [cardId]);
    },

    // UPDATE BOARD
    updateBoardSuccess(state, action) {
      const updatedBoard = action.payload;

      state.isLoading = false;
      state.board = updatedBoard;
    },

    // UPDATE COLUMN
    updateColumnSuccess(state, action) {
      const column = action.payload;

      state.isLoading = false;
      state.board.columns[column.id] = column;
    },

    // DELETE COLUMN
    deleteColumnSuccess(state, action) {
      const { columnId } = action.payload;
      const deletedColumn = state.board.columns[columnId];

      state.isLoading = false;
      state.board.columns = omit(state.board.columns, [columnId]);
      state.board.cards = omit(state.board.cards, [...deletedColumn.cardIds]);
      state.board.columnOrder = state.board.columnOrder.filter((c) => c !== columnId);
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getBoard() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await UserUtils.getKanban();
      try { response.data.data = JSON.parse(response.data.data); } catch (er){ console.log(er); }
      const board = response.data.data
      dispatch(slice.actions.getBoardSuccess(board));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateBoard(updateBoard) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const res = await UserUtils.updateKanban(updateBoard)
      if (res.success){
        dispatch(slice.actions.updateBoardSuccess(updateBoard));
      }
      //  dispatch(slice.actions.updateBoard({state: {board: updateBoard}}));
      //  dispatch(slice.actions.updateBoard());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createColumn(newColumn) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const column = {
        id: uuidv4(),
        name: newColumn.name,
        cardIds: []
      };
      dispatch(slice.actions.createColumnSuccess(column));
      dispatch(slice.actions.updateBoard());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateColumn(columnId, updateColumn) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.updateColumnSuccess({...updateColumn, id: columnId}));
      dispatch(slice.actions.updateBoard());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteColumn(columnId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.deleteColumnSuccess({ columnId }));
      dispatch(slice.actions.updateBoard());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function persistColumn(newColumnOrder) {
  return () => {
    dispatch(slice.actions.persistColumn(newColumnOrder));
  };
}

// ----------------------------------------------------------------------

export function persistCard(columns) {
  return () => {
    dispatch(slice.actions.persistCard(columns));
  };
}

// ----------------------------------------------------------------------

export function addTask({ card, columnId }) {
  return () => {
    dispatch(slice.actions.addTask({ card, columnId }));
    dispatch(slice.actions.updateBoard());
  };
}

// ----------------------------------------------------------------------

export function updateTask({ card, columnId, saveBoard }) {
  return () => {
    dispatch(slice.actions.updateTask({ card, columnId }));
    if (saveBoard){
      dispatch(slice.actions.updateBoard());
    }
  };
}

// ----------------------------------------------------------------------

export function deleteTask({ cardId, columnId }) {
  return (dispatch) => {
    dispatch(slice.actions.deleteTask({ cardId, columnId }));
    dispatch(slice.actions.updateBoard());
  };
}
