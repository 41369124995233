import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import { Manager } from 'socket.io-client';
// hooks
import useAuth from '../hooks/useAuth';
// utils
import { ServerUtils } from '../api_utils';

// ----------------------------------------------------------------------

const initialState = {
  socketManager: undefined,
  socket: undefined,
  serverPlatformVersion: ServerUtils.getVersion(),
  notification: undefined,
  notifications: [],
  isInitialized: false,
  connected: false,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { socketManager, socket } = action.payload;
    return {
      ...state,
      socketManager,
      socket,
      isInitialized: true,
      connected: true,
    };
  },
  FIRST_RESPONSE: (state, action) => {
    const { serverPlatformVersion, notification, notifications } = action.payload;
    return {
      ...state,
      serverPlatformVersion,
      notification,
      notifications,
    };
  },
  UPDATE_CONNECTION_STATUS: (state, action) => {
    const { connected } = action.payload;
    return {
      ...state,
      connected,
    };
  },
  ADD_NOTIFICATION: (state, action) => {
    const { notification } = action.payload;
    return {
      ...state,
      notifications: [...state.notifications, notification],
    };
  },
  UPDATE_NOTIFICATION: (state, action) => {
    const { notification } = action.payload;
    const existing = state.notifications.find(x => x.id === notification.id);
    const notis = state.notifications;
    if (existing){
      const index = state.notifications.indexOf(existing)
      notis[index] = notification;
    }
    return {
      ...state,
      notifications: notis,
    };
  },
  REMOVE_NOTIFICATION: (state, action) => {
    const { notification } = action.payload;
    const notis = state.notifications.filter(x => x.id !== notification.id);
    return {
      ...state,
      notifications: notis,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const SocketContext = createContext({
  ...initialState,
});

// ----------------------------------------------------------------------

SocketProvider.propTypes = {
  children: PropTypes.node,
};

let initialized = false;
let loggedOut = false;
let skipUserUpdateCheck = false;

function SocketProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { user, setUser, isAuthenticated } = useAuth();

  const initialize = async (newLogin = false) => {
    if ((!initialized && !state.isInitialized) || newLogin){
      try {
        initialized = true;
        const socketEndpoint = window.location.hostname.includes("localhost") ? "http://localhost:3003" : "https://socket.ethanblank.com"
        console.log(`[Socket] Attempting to connect to '${socketEndpoint}'`)
        const socketManager = new Manager(socketEndpoint)
        const socket = socketManager.socket("/");

        socket.on("disconnect", () => {
          console.log(`[Socket] Disconnected from '${socketEndpoint}'`)
          dispatch({
            type: 'UPDATE_CONNECTION_STATUS',
            payload: {
              connected: false,
            },
          });
        });
        socket.on("connect", () => {
          console.log(`[Socket] Connected to '${socketEndpoint}'`)
          socket.emit("connection_establish", user)
          dispatch({
            type: 'INITIALIZE',
            payload: {
              socketManager,
              socket,
            },
          });
        });
        socket.on("update_user", (data) => {
          console.log(`[Socket] Received User Update.`);
          updateUserSkipUserCheck(data.updatedUser);
        });
        socket.on("connection_established", (data) => {
          console.log(data);
          const serverPlatformVersion = data.platform_version
          let notification;
          // let notifications = [];
          if (data.notification) {
            notification = data.notification
          }
          // if (data?.messages && data.messages.length > 0) {
          //   notifications = data.messages;
          //   notifications.reverse();
          // }
          if (data.updatedUser) {
            skipUserUpdateCheck = true;
            console.log(data.updatedUser);
            setUser(data.updatedUser);
          }
          dispatch({
            type: 'FIRST_RESPONSE',
            payload: {
              serverPlatformVersion,
              notification,
              notifications: []
            },
          });
        })
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    if (skipUserUpdateCheck){
      skipUserUpdateCheck = false;
    } else if (user && !initialized && !state.isInitialized) {
      // user logged in (first time this session)
      initialize();
    } else if (initialized && !isAuthenticated && !loggedOut){
      // user logged out
      loggedOut = true;
    } else if (loggedOut){
      // user logged in after logging out
      initialize(true);
    }
    console.log('[Socket] USER_UPDATE', user)
  }, [user]);

  const addNotification = (notification) => {
    dispatch({
      type: 'ADD_NOTIFICATION',
      payload: {
        notification,
      },
    });
  }

  const updateNotification = (notification) => {
    dispatch({
      type: 'UPDATE_NOTIFICATION',
      payload: {
        notification,
      },
    });
  }

  const removeNotification = (notification) => {
    dispatch({
      type: 'REMOVE_NOTIFICATION',
      payload: {
        notification,
      },
    });
  }

  const updateUserSkipUserCheck = (updatedUser) => {
    skipUserUpdateCheck = true;
    setUser(updatedUser);
  }

  return (
    <SocketContext.Provider
      value={{
        ...state,
        addNotification,
        updateNotification,
        removeNotification,
        updateUserSkipUserCheck
      }}
    >
      {children}
    </SocketContext.Provider>
  );
}

export { SocketContext, SocketProvider };
