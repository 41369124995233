import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
//
import { Stack } from '@mui/material';
import { getActive } from '..';
import { NavItemRoot, NavItemSub } from './NavItem';
import { PaperStyle } from './style';

// ----------------------------------------------------------------------

NavListRoot.propTypes = {
  list: PropTypes.shape({
    children: PropTypes.array,
    path: PropTypes.string,
  }),
};

export function NavListRoot({ group, level = 0, }) {
  const menuRef = useRef(null);
  const { pathname } = useLocation();
  const active = getActive(group.path, pathname);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (open) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (group.items) {
    return (
      <>
        <NavItemRoot
          open={open}
          item={group}
          active={active}
          ref={menuRef}
          onMouseEnter={handleOpen}
          onMouseLeave={handleClose}
        />

        <PaperStyle
          level={level}
          active={active}
          open={open}
          anchorEl={menuRef.current}
          anchorOrigin={{ vertical: level === 0 ? 'bottom' : 'top', horizontal: level === 0 ? 'left' : 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          PaperProps={{
            onMouseEnter: handleOpen,
            onMouseLeave: handleClose,
          }}
        >
          <Stack>
            {group.items.map(item => (item.items ? 
              <NavListSub group={item} level={level + 1} />
              :
              <NavListRoot group={item} level={level + 1} />
            ))}
          </Stack>
        </PaperStyle>
      </>
    );
  }

  return level === 0 ? <NavItemRoot item={group} active={active} /> : <NavItemSub item={group} active={active} />;
}

// ----------------------------------------------------------------------

NavListSub.propTypes = {
  list: PropTypes.shape({
    children: PropTypes.array,
    path: PropTypes.string,
  }),
};

function NavListSub({ group, level = 0 }) {
  const menuRef = useRef(null);

  const { pathname } = useLocation();

  const active = getActive(group.path, pathname);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const hasChildren = group.items;

  if (hasChildren) {
    return (
      <>
        <NavItemSub
          ref={menuRef}
          open={open}
          item={group}
          active={active}
          onMouseEnter={handleOpen}
          onMouseLeave={handleClose}
        />
        
        <PaperStyle
          level={level}
          open={open}
          anchorEl={menuRef.current}
          anchorOrigin={{ vertical: level === 0 ? 'bottom' : 'top', horizontal: level === 0 ? 'left' : 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          PaperProps={{
            onMouseEnter: handleOpen,
            onMouseLeave: handleClose,
          }}
        >
          <Stack>
            {group.items.map(item => (item.items ? 
              <NavListSub group={item} level={level + 1} />
              :
              <NavListRoot group={item} level={level + 1} />
            ))}
          </Stack>
        </PaperStyle>
      </>
    );
  }

  return <NavItemSub item={group} active={active} />;
}
