import { Suspense, lazy } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// layouts
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import DashboardLayout from '../layouts/dashboard';
import MainLayout from '../layouts/main';
// guards
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import RoleBasedGuard from '../guards/RoleBasedGuard';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router({ settingsOpen, setSettingsOpen }) {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'forgot-password', element: <ResetPassword /> },
        { path: 'reset-password/:token', element: <ResetPassword /> },
        // { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout
            settingsOpen={settingsOpen}
            setSettingsOpen={setSettingsOpen}
          />
        </AuthGuard>
      ),
      children: [
        {
          path: 'home',
          element: <DashboardHome />
        },
        {
          path: 'games',
          children: [
            { path: 'cases/browse', element: <BrowseCases /> },
            { path: 'cases/official/:caseName', element: <OpenCase /> },
            { path: 'cases/custom/:caseId', element: <OpenCase /> },
            { path: 'cases/inventory', element: <UserInventory /> },
            { path: 'battles', element: <CaseBattles /> },
            { path: 'battles/view/:battleId', element: <CaseBattle /> },
            { path: 'battles/create', element: <CreateCaseBattle /> },
            { path: 'plinko', element: <Plinko /> },
          ]
        },
        {
          path: 'freelancing',
          children: [
            { path: 'overview', element: <RoleBasedGuard accessibleRoles={['developer']} children={<AdminOverview />}/> },
            { path: 'invoices', element: <RoleBasedGuard accessibleRoles={['developer']} children={<InvoicesView />}/> },
            { path: 'invoices/add', element: <RoleBasedGuard accessibleRoles={['developer']} children={<InvoiceAdd />}/> },
            { path: 'invoices/edit/:id', element: <RoleBasedGuard accessibleRoles={['developer']} children={<InvoiceAdd />}/> },
            { path: 'bills', element: <RoleBasedGuard accessibleRoles={['developer']} children={<BillsView />}/> },
            { path: 'clients', element: <RoleBasedGuard accessibleRoles={['developer']} children={<ClientsView />}/> },
          ]
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'account', element: <UserAccount /> },
            { path: ':id', element: <UserProfile /> },
            // { path: 'mail', element: <Mail /> },
          ],
        },
        {
          path: '',
          element: <Navigate to="/dashboard/user/account" replace />,
        },
      ],
    },

    // Admin dashboard
    {
      path: 'admin',
      element: (
        <RoleBasedGuard accessibleRoles={['developer', 'admin']}>
          <DashboardLayout
            settingsOpen={settingsOpen}
            setSettingsOpen={setSettingsOpen}
          />
        </RoleBasedGuard>
      ),
      children: [
        { path: 'administration', element: <RoleBasedGuard accessibleRoles={['developer']} children={<ServerAdministration />}/> },
        { path: 'kanban', element: <RoleBasedGuard accessibleRoles={['developer']} children={<Kanban />}/> },
        { path: 'management', element: <RoleBasedGuard accessibleRoles={['developer']} children={<AdminManagement />}/> },
        { path: 'management/:tab', element: <RoleBasedGuard accessibleRoles={['developer']} children={<AdminManagement />}/> },
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout includeFooter={false} />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'music', element: <MusicLibrary /> },
        { path: 'showroom', element: <Showroom /> },
        // { path: 'about-us', element: <About /> },
        // { path: 'contact-us', element: <Contact /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'invoice/view/:id', element: <InvoiceView /> },
      ],
    },
    { path: '*', element: <NotFound /> },
  ]);
}

// Main
// ----------------------------------------------------------------------
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const Showroom = Loadable(lazy(() => import('../pages/Showroom')));
const MusicLibrary = Loadable(lazy(() => import('../pages/MusicLibrary')));
// Authentication
// ----------------------------------------------------------------------
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// Dashboard
// ----------------------------------------------------------------------


// Home
const DashboardHome = Loadable(lazy(() => import('../pages/dashboard/Home')))

// My Account
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail'))); // messages
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount'))); // settings
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile'))); // not sure if this will be used

// Developer
const ServerAdministration = Loadable(lazy(() => import('../pages/dashboard/developer/ServerAdministration')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/developer/Kanban')));

// Admin
const AdminOverview = Loadable(lazy(() => import('../pages/dashboard/admin/Overview')));
const AdminManagement = Loadable(lazy(() => import('../pages/dashboard/admin/Management')));

// Invoices
const InvoiceView = Loadable(lazy(() => import('../pages/dashboard/invoices/InvoiceView')));
const InvoicesView = Loadable(lazy(() => import('../pages/dashboard/invoices/InvoicesView')));
const InvoiceAdd = Loadable(lazy(() => import('../pages/dashboard/invoices/InvoicesAdd')));

// Bills
const BillsView = Loadable(lazy(() => import('../pages/dashboard/bills/BillsView')));

// Clients
const ClientsView = Loadable(lazy(() => import('../pages/dashboard/clients/ClientsView')));

// Cases
const BrowseCases = Loadable(lazy(() => import('../pages/dashboard/cases/Browse')));
const OpenCase = Loadable(lazy(() => import('../pages/dashboard/cases/OpenCase')));
const CaseBattles = Loadable(lazy(() => import('../pages/dashboard/cases/CaseBattles')));
const CaseBattle = Loadable(lazy(() => import('../pages/dashboard/cases/CaseBattle')));
const CreateCaseBattle = Loadable(lazy(() => import('../pages/dashboard/cases/CreateCaseBattle')));
const UserInventory = Loadable(lazy(() => import('../pages/dashboard/cases/UserInventory')));

const Plinko = Loadable(lazy(() => import('../pages/dashboard/games/plinko')));