// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_ADMIN = '/admin';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  forgotPassword: path(ROOTS_AUTH, '/forgot-password'),
  resetPassword: path(ROOTS_AUTH, '/reset-password/:token'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  form: {
    custom: '/form/custom/:id',
  },
  invoice: {
    root: '/invoice/',
    view: '/invoice/view/:id',
  },
  cases: '/cases',
  battles: '/battles',
  battle: '/battles/:battleId',
  openCase: '/cases/:caseName',
  openCustomCase: '/cases/custom/:caseId',
  myInventory: '/my-inventory',
  music: '/music',
  showroom: '/showroom',
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  home: {
    root: path(ROOTS_DASHBOARD, '/home'),
  },
  games: {
    root: path(ROOTS_DASHBOARD, '/games'),
    cases: {
      root: path(ROOTS_DASHBOARD, '/games/cases'),
      browse: path(ROOTS_DASHBOARD, '/games/cases/browse'),
      openOfficial: path(ROOTS_DASHBOARD, '/games/cases/official'),
      openCustom: path(ROOTS_DASHBOARD, '/games/cases/custom'),
      inventory: path(ROOTS_DASHBOARD, '/games/cases/inventory'),
    },
    battles: {
      root: path(ROOTS_DASHBOARD, '/games/battles'),
      view: path(ROOTS_DASHBOARD, '/games/battles/view'),
      create: path(ROOTS_DASHBOARD, '/games/battles/create'),
    },
    plinko: path(ROOTS_DASHBOARD, '/games/plinko')
  },
  freelancing: {
    root: path(ROOTS_DASHBOARD, '/freelancing'),
    overview: path(ROOTS_DASHBOARD, '/freelancing/overview'),
    invoices: {
      root: path(ROOTS_DASHBOARD, '/freelancing/invoices'),
      create: path(ROOTS_DASHBOARD, '/freelancing/invoices/add'),
      edit: path(ROOTS_DASHBOARD, '/freelancing/invoices/edit'),
    },
    bills: {
      root: path(ROOTS_DASHBOARD, '/freelancing/bills'),
    },
    clients: {
      root: path(ROOTS_DASHBOARD, '/freelancing/clients'),
    }
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    user: path(ROOTS_DASHBOARD, '/user/:id'),
    mail: path(ROOTS_DASHBOARD, '/user/mail'),
  },
};

export const PATH_ADMIN = {
  root: ROOTS_ADMIN,
  management: path(ROOTS_ADMIN, '/management'),
  administration: path(ROOTS_ADMIN, '/administration'),
  kanban: path(ROOTS_ADMIN, '/kanban'),
}
