import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
// @mui
import { AppBar, Box, Button, Dialog, Divider, Stack, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER, NAVBAR, defaultSettings } from '../../../config';
// components
import Iconify from '../../../components/Iconify';
import Logo from '../../../components/Logo';
import { IconButtonAnimate } from '../../../components/animate';
//
import { ServerUtils } from '../../../api_utils';
import Scrollbar from '../../../components/Scrollbar';
import useAuth from '../../../hooks/useAuth';
import useSettings from '../../../hooks/useSettings';
import useSocket from '../../../hooks/useSocket';
import { PATH_AUTH } from '../../../routes/paths';
import AccountPopover from './AccountPopover';
import BalancePopover from './BalancePopover';

const semver = require('semver');


// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur({ blur: 32, color: theme.palette.background.default, opacity: .05 }),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  // borderBottomWidth: 1,
  // borderBottomStyle: 'solid',
  // borderBottomColor: theme.palette.background.paper,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

export default function DashboardHeader({ onOpenSidebar, isCollapse = false, verticalLayout = false, settingsOpen, setSettingsOpen, headerContent = (<></>) }) {

  const { themeMode, themeDirection, themeColorPresets, themeStretch, themeLayout, onResetSetting } = useSettings();

  const notDefault =
    themeMode !== defaultSettings.themeMode ||
    themeDirection !== defaultSettings.themeDirection ||
    themeColorPresets !== defaultSettings.themeColorPresets ||
    themeLayout !== defaultSettings.themeLayout ||
    themeStretch !== defaultSettings.themeStretch;
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const isDesktop = useResponsive('up', 'lg');
  const { socket, serverPlatformVersion, notification } = useSocket();


  const [alert, setAlert] = useState(undefined)
  const [serverNotification, setServerNotification] = useState(notification);
  const [alertOpen, setAlertOpen] = useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (serverPlatformVersion) {
      if (semver.lt(ServerUtils.getVersion(), serverPlatformVersion)) {
        logout();
        navigate(PATH_AUTH.login);
        enqueueSnackbar("You have been logged out.");
        setAlert({
          type: "error",
          action: () => { window.location.reload() },
          actionText: "Update Now",
          title: "Your Version Is Out-Of-Date",
          description: `You are running version ${ServerUtils.getVersion()}, but the latest avaliable version is ${serverPlatformVersion}.`,
          canClose: false,
        })
        setAlertOpen(true)
      }
    }
  }, [serverPlatformVersion])

  useEffect(() => {
    if (notification) {
      const n = notification;
      const nExpiration = new Date(n.expires)
      if (nExpiration > new Date()) {
        if (n.canClose) {
          n.action = () => setServerNotification({ ...notification, dismissed: true })
          n.actionText = "Dismiss";
        }
        setServerNotification({ ...n, dismissed: false })
      }
    }
  }, [notification])

  useEffect(() => {
    if (socket) {
      socket.on("receive_global_notification", (data) => {
        setAlert(data);
        setAlertOpen(true)
      })
    }
  }, [])

  const handleToggle = () => {
    setSettingsOpen((prev) => !prev);
  };

  return (
    <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 3 },
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}
        {/* <AlertBar notification={notification} /> */}
        {headerContent}
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <BalancePopover />
          {/* <ContactsPopover /> */}
          {/* <ChatPopover /> */}
          {/* <IconButton color='primary' onClick={() => navigate("/")}>
            <Iconify icon="material-symbols:home"/>
          </IconButton>
          <IconButton color='primary' onClick={handleLogout}>
            <Iconify icon="material-symbols:logout"/>
          </IconButton> */}
          <AccountPopover onToggle={handleToggle}/>
        </Stack>
      </Toolbar>
      <Dialog open={alertOpen} onClose={() => {
        if (alert.canClose) {
          setAlertOpen(false);
        }
      }} fullWidth maxWidth="sm">
        {alert !== undefined &&
          <>
            <Typography color={alert.type} variant='h4' textAlign="center" sx={{ p: 1 }}>{alert.title}</Typography>
            <Divider />
            <Scrollbar sx={{ maxHeight: 200, p: 2 }}>
              <Typography variant='body2' textAlign="center">{alert.description}</Typography>
            </Scrollbar>
            <Stack direction="row" justifyContent={alert.canClose ? "space-between" : "center"} sx={{ p: 1 }}>
              {alert.canClose &&
                <Button onClick={() => setAlertOpen(false)}>Close</Button>
              }
              {alert.action !== undefined &&
                <Button variant='contained' onClick={alert.action}>{alert.actionText}</Button>
              }
            </Stack>
          </>
        }
      </Dialog>
    </RootStyle>
  );
}
