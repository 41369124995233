/* eslint-disable */

import { useState } from 'react';
// @mui
import { Box, Button, ButtonGroup, CardActionArea, Dialog, DialogContent, DialogTitle, Grid, RadioGroup, Stack } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { ColorBox } from 'material-ui-color';
// hooks
import useSettings from '../../hooks/useSettings';
//
import { BoxMask, BoxMask2 } from '.';

// ----------------------------------------------------------------------

const BoxStyle = styled(CardActionArea)(({ theme }) => ({
  height: 48,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.disabled,
  border: `solid 1px ${theme.palette.grey[500_12]}`,
  borderRadius: Number(theme.shape.borderRadius) * 1.25,
}));

// ----------------------------------------------------------------------

export default function SettingColorPresets() {
  const { themeColorPresets, themeColorCustom, onChangeColor, colorOption } = useSettings();
  const [customColorOpen, setCustomColorOpen] = useState(false);
  const [customColorTemp, setCustomColorTemp] = useState(themeColorCustom ? themeColorCustom.main : '#fff');

  const buildCustomColor = (color) => {
    const shadeColor = (color, percent) => {

      let R = parseInt(color.substring(1, 3), 16);
      let G = parseInt(color.substring(3, 5), 16);
      let B = parseInt(color.substring(5, 7), 16);

      R = parseInt(R * (100 + percent) / 100);
      G = parseInt(G * (100 + percent) / 100);
      B = parseInt(B * (100 + percent) / 100);

      R = (R < 255) ? R : 255;
      G = (G < 255) ? G : 255;
      B = (B < 255) ? B : 255;

      R = Math.round(R)
      G = Math.round(G)
      B = Math.round(B)

      let RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
      let GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
      let BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));

      return "#" + RR + GG + BB;
    }

    // Calculate contrast text color
    const contrastText = parseInt(color, 16) > 0xffffff / 2 ? '#000000' : '#ffffff';

    return {
      name: 'custom',
      lighter: shadeColor(color, 60),
      light: shadeColor(color, 30),
      main: color,
      dark: shadeColor(color, -30),
      darker: shadeColor(color, -60),
      contrastText,
    };
  };


  return (
    <>
      <RadioGroup name="themeColorPresets" value={themeColorPresets} onChange={(e) => {
        onChangeColor(e, undefined)
      }}>
        <Grid dir="ltr" container spacing={1.5}>
          {colorOption.map((color) => {
            const colorName = color.name;
            const colorValue = color.value;
            const isSelected = themeColorPresets === colorName;
            const isCustom = colorName === "custom"

            return (
              <Grid key={colorName} item xs={isCustom ? 12 : 4}>
                <BoxStyle
                  sx={{
                    ...(isSelected && {
                      bgcolor: alpha(colorValue, 0.08),
                      border: `solid 2px ${colorValue}`,
                      boxShadow: `inset 0 4px 8px 0 ${alpha(colorValue, 0.24)}`,
                    }),
                  }}
                >
                  {isCustom ?
                    <>
                      {/* <Input
                        sx={{ px: 2 }}
                        placeholder='#fff'
                        value={colorValue}
                        
                        onChange={(e) => {
                          var reg=/^#([0-9a-f]{3}){1,2}$/i;
                          const val = e.target.value;
                          if (reg.test(val)){
                            onChangeColor(e, buildCustomColor(val))
                          }
                        }}
                      /> */}
                      <Button size='large' onClick={() => setCustomColorOpen(true)} fullWidth>Custom</Button>
                    </>
                    :
                    <Stack direction="row" alignContent="center" alignItems="center">
                      <Box
                        sx={{
                          width: 24,
                          height: isCustom ? 24 : 14,
                          m: isCustom ? 1 : 0,
                          borderRadius: '50%',
                          bgcolor: colorValue,
                          transform: 'rotate(-45deg)',
                          transition: (theme) =>
                            theme.transitions.create('all', {
                              easing: theme.transitions.easing.easeInOut,
                              duration: theme.transitions.duration.shorter,
                            }),
                          ...(isSelected && { transform: 'none' }),
                        }}
                      />
                      {isCustom ?
                        <BoxMask2 value={colorName} />
                        :
                        <BoxMask value={colorName} />
                      }
                    </Stack>
                  }
                </BoxStyle>
              </Grid>
            );
          })}
        </Grid>
      </RadioGroup>
      <Dialog maxWidth="sm" open={customColorOpen} onClose={() => setCustomColorOpen(false)}>
        <DialogTitle sx={{ p: 1, textAlign: 'center', backgroundColor: customColorTemp }}>
          Choose Custom Color
        </DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <ColorBox value={customColorTemp} onChange={(e) => {
            setCustomColorTemp(`#${e.hex}`)
          }} />
        </DialogContent>
        <ButtonGroup fullWidth>
          <Button
            color='primary'
            variant='contained'
            fullWidth
            onClick={() => setCustomColorOpen(false)} 
            sx={{ borderRadius: 0, backgroundColor: customColorTemp }}
          >
            Close
          </Button>
          <Button
            color='primary'
            variant='contained'
            fullWidth
            onClick={() =>
              onChangeColor('custom', buildCustomColor(`${customColorTemp}`))
            } 
            sx={{ borderRadius: 0, backgroundColor: customColorTemp }}
          >
            Apply
          </Button>
        </ButtonGroup>
      </Dialog>
    </>
  );
}
