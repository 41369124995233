
export default class ServerUtils {

  static getVersion() {
    return "0.1.8";
  }

  static apiEndpoint() {
    if (window.location.hostname.includes("localhost")) {
      return "/"
    }
    return "https://api.ethanblank.com/"
  }

  static getUserImageUrl(user) {
    if (user && user.imageUrl && user.imageUrl.length > 0) {
      return `https://api.ethanblank.com/public/images/avatars/users/${user?.imageUrl}`
    }
    return ""
  }

  static getUserBannerUrl(user) {
    if (user && user.bannerUrl && user.bannerUrl.length > 0) {
      if (user.bannerUrl.includes("http")) {
        return user.bannerUrl;
      }
      return `https://api.ethanblank.com/public/images/banners/users/${user?.bannerUrl}`
    }
    return '../img/default-banner.jpg'
  }

  static getCustomCaseImage(caseData) {
    if (caseData && caseData.image_url && caseData.image_url.length > 0) {
      if (caseData.image_url.includes("http")) {
        return caseData.image_url;
      }
      if (!window.location.hostname.includes('localhost')) {
        return `https://api.ethanblank.com/public/images/cases/${caseData.image_url}`
      }
      return `http://localhost:3002/public/images/cases/${caseData.image_url}`;
    }
    return  `http://localhost:3002/public/images/cases/default.png`
  }

  static getPostImageUrl(post) {
    // if (post && post.imageUrl && post.imageUrl.length > 0) {
    //   if (post.imageUrl.includes("http")) {
    //     return post.imageUrl;
    //   }
    //   return `https://images.stockgame.online/posts/blog/${post?.imageUrl}`
    // }
    return "../img/default-banner.jpg"
  }

  static async POST(url, body) {
    return new Promise((resolve, reject) => {
      fetch(this.apiEndpoint() + url, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      }).then((response) => resolve(response.json()))
        .catch((error) => {
          console.error("POST error: ", error)
          reject(error);
        });
    });
  }

  static async GET(url) {
    return new Promise((resolve, reject) => {
      fetch(this.apiEndpoint() + url, {
        credentials: 'include',
      })
        .then((response) => resolve(response.json()))
        .catch((error) => {
          console.error("GET error: ", error)
          reject(error);
        });
    });
  }

  static async sendMessage(msg) {
    return this.POST('api/messages/send', { message: msg })
  }

  static async deleteMessage(msg) {
    return this.POST('api/messages/delete', { message: msg })
  }

  static async unlockRequest(password) {
    return this.POST('unlock', { password })
  }
}
