/* eslint-disable */
import ServerUtils from "./helpers";

export default class CaseUtils {
  static async getAll() {
    return ServerUtils.GET('api/cases/get-all')
  }
  static async getByName(caseName) {
    return ServerUtils.GET(`api/cases/get-by-name/${caseName}`)
  }
  static async openCaseByName(caseName, numOfCases, rarities = undefined) {
    return ServerUtils.POST(`api/cases/open-by-name/${encodeURIComponent(caseName)}/${numOfCases}`, { rarities })
  }
  static async getInventory(userId){
    return ServerUtils.GET(`api/cases/inventory/${userId}`);
  }
  static async sellSkins(skins, userId){
    return ServerUtils.POST(`api/cases/inventory/sell-skins`, {skins, userId})
  }
  static async getAllSkins(){
    return ServerUtils.GET(`api/cases/skins/get-all`);
  }
  static async saveCustomCase(data){
    return ServerUtils.POST(`api/cases/custom/save`, {data});
  }
  static async getCustomCases(){
    return ServerUtils.GET(`api/cases/custom/get-all`);
  }
  static async getCustomCaseById(id){
    return ServerUtils.GET(`api/cases/custom/get/${id}`);
  }
  static async openCaseById(caseId, numOfCases, settings) {
    return ServerUtils.POST(`api/cases/custom/open/${caseId}/${numOfCases}`, {settings});
  }
  static async createBattle(battle) {
    return ServerUtils.POST(`api/cases/create-battle`, {battle})
  }
  static async getBattleById(battleId) {
    return ServerUtils.GET(`api/cases/battles/get-by-id/${battleId}`)
  }
  static async getActiveBattles() {
    return ServerUtils.GET(`api/cases/battles/get-all-active`)
  }
  static async getUserHistory(userId) {
    return ServerUtils.GET(`api/cases/battles/get-history/${userId}`)
  }
  static async getUserBattles(userId) {
    return ServerUtils.GET(`api/cases/battles/for-user/${userId}`)
  }
}