import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Container, Link, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/Logo';
import Page from '../../components/Page';
// sections
import { LoginForm } from '../../sections/auth/login';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const SectionStyle = styled('div')(({ theme }) => ({
  width: "90vw",
  background: 'url(https://static.vecteezy.com/system/resources/previews/012/483/071/original/abstract-violet-and-purple-liquid-gradient-wave-decorative-background-smooth-flowing-layered-curved-waves-wallpaper-vector.jpg)',
  backgroundSize: 'cover',
  filter: 'hue-rotate(20deg)',
  borderStyle: 'solid',
  borderWidth: 0,
  margin: 16,
  borderColor: theme.palette.background.default,
  borderRadius: 32,
  webkitBorderRadius: 64,

}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'start',
  padding: theme.spacing(6, 6),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuth();

  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Login">
      <RootStyle>

        <Container maxWidth="sm">
          <ContentStyle>
            <Logo />
            <Stack direction="row" alignItems="center" sx={{ mb: 5, mt: 8 }}>
              <Box>
                <Typography variant="h3" gutterBottom>
                  Sign in
                </Typography>
                <Typography variant="body2">
                  New user?&nbsp;
                  <Link variant="body2" component={RouterLink} to={PATH_AUTH.register}>
                    Create an account
                  </Link>
                </Typography>
              </Box>
            </Stack>

            <LoginForm />
          </ContentStyle>
        </Container>

        {mdUp && (
          <SectionStyle/>
        )}
      </RootStyle>
    </Page>
  );
}
