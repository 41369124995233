// ----------------------------------------------------------------------

import cssStyles from '../../utils/cssStyles';

export default function Popover(theme) {
  return {
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: theme.customShadows.dropdown,
          borderRadius: Number(theme.shape.borderRadius) * 1.5,
          ...cssStyles().bgBlur({ blur: 32, color: theme.palette.background.paper, opacity: .33 }),
        },
      },
    },
  };
}
