import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// @mui
import { Divider, Drawer, Stack, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

// hooks
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
import useResponsive from '../../../hooks/useResponsive';

// utils
import cssStyles from '../../../utils/cssStyles';

// config
import { NAVBAR } from '../../../config';

// components
import Logo from '../../../components/Logo';
import Scrollbar from '../../../components/Scrollbar';
import { MotionInView, varFade } from '../../../components/animate';
import { NavSectionVertical } from '../../../components/nav-section';
import useAuth from '../../../hooks/useAuth';
import useSocket from '../../../hooks/useSocket';
import CollapseButton from './CollapseButton';
import navConfig from './NavConfig';

const semver = require('semver');

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }) {
  const theme = useTheme();
  const { pathname } = useLocation();
  const isDesktop = useResponsive('up', 'lg');
  const { user } = useAuth()
  const { socket, connected, notifications, serverPlatformVersion } = useSocket()
  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } = useCollapseDrawer();
  const [navConfigFilled, setNavConfigFilled] = useState(navConfig(user.id, -1))

  useEffect(() => {
    if (socket) {
      setNavConfigFilled(navConfig(user.id, notifications.length))
    }
  }, [notifications])

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <>
      <Stack
        spacing={1}
        sx={{
          p: 2,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ maxHeight: 48, overflow: 'hidden' }}>
          <Stack direction="row" alignItems="center" >
            <Logo />
          </Stack>

          {isDesktop && !isCollapse && (
            <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
          )}
        </Stack>
      </Stack>
      <Divider />

      <Scrollbar
        sx={{
          height: 1,
          '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
        }}
      >
        <NavSectionVertical navConfig={navConfigFilled} isCollapse={isCollapse} />

        {/* <Box sx={{ flexGrow: 1 }} /> */}

      </Scrollbar>
    </>

  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),

      }}
    >
      {!isDesktop && (
        <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}>
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'dashed',
              bgcolor: 'background.default',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                boxShadow: (theme) => theme.customShadows.z24,
              }),
              ...cssStyles(theme).bgBlur({ blur: 64, color: theme.palette.background.paper, opacity: .05 }),
              overflowX: 'hidden'
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
