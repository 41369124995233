import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';

// ----------------------------------------------------------------------

RHFTextField.propTypes = {
  name: PropTypes.string,
};


export default function RHFTextField({ name, ChangedEvent = null, ...other }) {

  const { control } = useFormContext();

  const wrapHandleChange = async (target) => {
    if (typeof ChangedEvent === 'function')
      ChangedEvent(target);
    // 👉️ your logic after
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ...field }, fieldState: { error } }) => (
        <TextField {...field} fullWidth error={!!error} helperText={error?.message} {...other} onChange={async ({ target }) => {
          onChange(target.value);
          wrapHandleChange(target);
        }} />
      )}
    />
  );
}
